import { useTestSpecifications } from "@/app/features/testsuites/specifications/Context"
import { FastFillEditor, FastFillItem } from "@/components/raytd/fast-fill-editor"
import { BorderlessCard } from "@/components/ui/borderless-card"
import { Button } from "@/components/ui/button"
import { SubCategory } from './SubCategory';
import { CardContent } from "@/components/ui/card"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Textarea } from "@/components/ui/textarea"
import { zodResolver } from "@hookform/resolvers/zod"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { TestCase } from "store/src/lib/tests/entity"
import { v4 as uuid } from 'uuid'
import * as z from "zod"

const testSchema = z.object({
  label: z.string().min(1, "Test Name is required"),
  category: z.string().min(1, "Category is required"),
  subcategory: z.string().min(1, "Sub Category is required"),
  description: z.string().min(1, "Test Specification is required"),
})

type TestFormValues = z.infer<typeof testSchema>

interface TestFormProps {
  onSubmit: (data: TestFormValues) => void
  onDiscard: () => void
  specification: TestCase;
}

const TestSpecificationForm: React.FC<TestFormProps> = ({ specification, onSubmit, onDiscard }) => {

  const editorRef = React.useRef<HTMLDivElement>(null);

  const { categories: initialCategories, subcategories: initialSubCategories } = useTestSpecifications();

  const [categories, setCategories] = useState<string[]>(null);
  const [subcategories, setSubcategories] = useState<{category:string;subcategory:string;}[]>(null);

  const form = useForm<TestFormValues>({
    resolver: zodResolver(testSchema),
    defaultValues: {
      label: "",
      category: "",
      subcategory: "",
      description: "",
    },
  });

  const [recommendations, setRecommendations] = useState<FastFillItem[]>([]);
  const [observations, setObservations] = useState<FastFillItem[]>([]);

  useEffect(() => {
    form.reset(specification);
    setRecommendations(specification.recommendations.map(
      (recommendation) => ({
        id: recommendation.id, content: recommendation.description, delete: false, uuid: uuid()
      })
    ));

    setObservations(specification.observations.map(
      (observation) => ({
        id: observation.id, content: observation.description, delete: false, uuid: uuid()
      })
    ));

    setCategories(initialCategories);
    setSubcategories(initialSubCategories);

  }, [specification, initialCategories, initialSubCategories, form.reset]);

  useEffect(() => {
    console.debug('categories', categories);
  }, [categories]);

  const handleUpdateObservations = (items: FastFillItem[]) => {
    setObservations(items);
  }

  //handle cdlikc outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      console.debug('handleClickOutside', event.target);
      if (editorRef.current && !editorRef.current.contains(event.target as Node)) {
        form.clearErrors();
        console.debug('outside');
      } else {
        console.debug('inside');
      }
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    }
  }, [editorRef, form]);

  const selectedCategory = form.watch('category');

  return (
    <BorderlessCard className="w-full px-2 py-4" ref={editorRef}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <CardContent className="space-y-4 px-2">

            <div className="grid grid-cols-2 gap-4">
              <FormField
                control={form.control}
                name="label"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Test Name*</FormLabel>
                    <FormControl>
                      <Input placeholder="Enter test name" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="flex flex-row items-start justify-end gap-4 w-full">
                <Button variant="outline" type="button" onClick={onDiscard}>
                  Discard
                </Button>
                <Button type="submit">Save</Button>
              </div>

            </div>

            <div className="flex space-x-4">

              <CategorySelect
                form={form}
                name="category"
                label="Category"
                items={categories?.map((category) => ({ value: category, label: category }))}
                onAddItem={(item) => {
                  categories.push(item.value);
                  form.setValue('category', item.value);
                }}
              />

              <CategorySelect
                form={form}
                name="subcategory"
                label="Sub Category"
                items={subcategories?.filter(sub => sub.category === selectedCategory).map((item) => ({ value: item.subcategory, label: item.subcategory }))}
                onAddItem={(item) => {
                  subcategories.push({ category: selectedCategory, subcategory: item.value });
                  form.setValue('subcategory', item.value);
                }}
              />
            </div>

            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Test Specification*</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder="Enter test specification"
                      className="min-h-[100px]"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="w-full grid grid-cols-2">
              <FastFillEditor
                title="Observations"
                initialItems={observations}
                onChange={handleUpdateObservations}
              />
              <FastFillEditor
                title="Recommendations"
                initialItems={recommendations} />

            </div>
          </CardContent>
        </form>
      </Form>
    </BorderlessCard>
  )
}

export default TestSpecificationForm;


//import * as React from "react"
import { Check, ChevronsUpDown } from "lucide-react"
import { cn } from "@/lib/utils"
//import { Button } from "@/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"

export function CategorySelect({ form, name, label, items, onAddItem }) {
  const [open, setOpen] = React.useState(false)
  const [inputValue, setInputValue] = React.useState("")

  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className="flex flex-col flex-1">
          <FormLabel>{label}</FormLabel>
          <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  role="combobox"
                  aria-expanded={open}
                  className="w-full justify-between"
                >
                  {field.value
                    ? items.find((item) => item.value === field.value)?.label
                    : `Select ${label}`}
                  <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>

            <PopoverContent className="p-0 w-[var(--radix-popover-trigger-width)]" align="center">
              <Command>
                <CommandInput
                  placeholder={`Search ${label}...`}
                  onValueChange={setInputValue}
                />
                <CommandEmpty>
                  <Button
                    variant="ghost"
                    className="w-full justify-start"
                    onClick={() => {
                      if (inputValue) {
                        const newItem = { value: inputValue.toLowerCase(), label: inputValue }
                        onAddItem(newItem)
                        field.onChange(newItem.value)
                        setOpen(false)
                        setInputValue("")
                      }
                    }}
                  >
                    Add "{inputValue}"
                  </Button>
                </CommandEmpty>
                <CommandList>
                  <CommandGroup>
                    {items?.map((item) => (
                      <CommandItem
                        key={item.value}
                        onSelect={() => {
                          field.onChange(item.value === field.value ? "" : item.value)
                          setOpen(false)
                        }}
                      >
                        <Check
                          className={cn(
                            "mr-2 h-4 w-4",
                            field.value === item.value ? "opacity-100" : "opacity-0"
                          )}
                        />
                        {item.label}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
