import { debounce, isEqual, differenceWith } from 'lodash';
import {
    Input,
    Layout,
    Card, Button,
    Box,
    Modal,
    Text,
    MessageModalLayout,
    Dropdown,
    ToggleSwitch,
    Loader,
} from "@wix/design-system";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CustomField } from "store/src/lib/tests/entity";
import { useForm, Controller } from 'react-hook-form';
import { CustomFieldType } from 'store/src/lib/tests/entity';
import DataEditorField from './DataEditorField';
import GenericFormField from './GenericFormField';
import { DataRecord } from './DataEditorField';
import {useSubmitWithSaving} from '@app.raytd.com/ui';

type CustomFieldEditorProps = {
    customField: CustomField;
    initialValues: CustomField;
    onSave: (customField: Partial<CustomField>) => Promise<boolean>;
    onSuccessfulSave?: (data: CustomField) => void;
    onDirtyChange?: (isDirty: boolean) => void;
    onChange?: (data: CustomField) => void;
}

type FieldTypeDetails = {
    title: string;
    information: string;
    value_type: string;
    placeholder?: boolean;
    hasData?: boolean;
    dataEditorLabel?: string;
    dataEditorInfo?: string;
};

const fieldType: Partial<Record<CustomFieldType, FieldTypeDetails>> = {
    "text": {
        title: "Short Text",
        information: "This field will provide users a single line text box to fill in. Users can input letters, numbers, characters and emojis.",
        value_type: 'string'
    },
    "textarea": {
        title: "Paragraph Text",
        information: "This field will provide users with a paragraph sized text box to fill in, plus quick fill responses they can insert. Users can also add their own quick fill responses in the mobile application.",
        value_type: 'string'
    },
    "number": {
        title: "Short Number",
        information: "This field will provide users a single line numerical box to fill in. Users can input whole numbers and decimal places.",
        value_type: 'number'
    },
    "scrollable_number": {
        title: "Scrolling Number",
        information: "This field will provide users with a scrollable number pad to input numbers.",
        value_type: 'number'

    },
    "select": {
        title: "List",
        information: "This field will provide users with a dropdown to select from a list of options.",
        value_type: 'string',
        hasData: true,
        dataEditorLabel: 'Dropdown Options',
        dataEditorInfo: 'This will comprise the selections available.'
    },
    "date": {
        title: "Date (DD/MM/YYYY)",
        information: "This field will provide users with a date picker to select a date.",
        value_type: 'date'
    },
    "switch": {
        title: "Toggle (On/Off)",
        information: "This field will provide users with a switch to toggle on or off. It will default to off",
        value_type: 'boolean',
        placeholder: false
    },
    "checkbox": {
        title: "Checkbox (Selected/Blank)",
        information: "This field will provide users with a checkbox to select or deselect.",
        value_type: 'boolean',
        placeholder: false
    },
    "radio": {
        title: "Radio (Selected/Blank)",
        information: "This field will provide users with a radio button to select one option from a list.",
        value_type: 'string',
        placeholder: false,
        hasData: true,
        dataEditorLabel: 'Radio Options',
        dataEditorInfo: 'Add options for the radio button'
    },


};

export const fieldTypeConfiguration = fieldType;

const DiscardChangesModal = ({ visible, onConfirm, onCancel }: { visible: boolean, onConfirm: () => void, onCancel: () => void }) => {
    return (
        <Modal
            isOpen={visible}
            onRequestClose={onCancel}
            shouldDisplayCloseButton={true}
            shouldCloseOnOverlayClick={true}
            contentLabel="Discard Changes"
            zIndex={1000}
            scrollableContent={false}
            height="auto"
        >

            <MessageModalLayout
                onCloseButtonClick={onCancel}
                title="Discard Changes"
                primaryButtonText="Discard"
                secondaryButtonText="Cancel"
                primaryButtonOnClick={onConfirm}
                secondaryButtonOnClick={onCancel}
            >
                <Text>Are you sure you want to discard changes and revert to the original field configuration?</Text>
            </MessageModalLayout>
        </Modal>
    )
}

export const CustomFieldEditor = ({ customField, initialValues, onSave, onSuccessfulSave, onDirtyChange, onChange }: CustomFieldEditorProps) => {

    const { control, handleSubmit, reset, watch, formState: { errors, isDirty } } = useForm<CustomField>({
        defaultValues: initialValues
    });

    const [errorMessage, setErrorMessage] = useState('');
    const [saveStatus, setSaveStatus] = useState<'idle' | 'saving' | 'error' | 'success'>('idle');
    const [initialState, setInitialState] = useState<CustomField>(initialValues);
    const [changes, setChanges] = useState<CustomField>(initialValues);
    const [showDiscardModal, setShowDiscardModal] = useState(false);
    const watchedValues = watch();

    useEffect(() => {

        if (customField === undefined) {
            return;
        }

        reset(customField, {
            keepDefaultValues: true
        })
    }, [customField, reset])

    const onSubmit = useCallback(async (data: CustomField): Promise<boolean> => {

        setSaveStatus('saving');
        if (data.field_name === '') {
            data.field_name = data.label.toLowerCase().replace(/ /g, '_');
        }

        return onSave(data).then((result) => {
            if (result) {
                onSuccessfulSave?.(data);
                setSaveStatus('success');
                reset(data);
                return true;
            }
        }).catch((error) => {
            setSaveStatus('error');
            setErrorMessage(error?.message ?? 'Unknown error');
            return false;
        });
    }, [onSave, onSuccessfulSave, reset]);

    const { handleSubmit: handleSave, saving, error } = useSubmitWithSaving(onSubmit);

    const onDelete = () => {
        reset(initialState);
        handleSubmit(onSubmit)();
        setShowDiscardModal(false);
    }

    const _handleOrderChange = useCallback((data: DataRecord[]) => {
        handleSubmit(onSubmit)();
    }, [onSubmit, handleSubmit]);

    // useEffect(() => {
    //     console.info('errors', errors, saveStatus);
    //     console.info('watched values', watchedValues);
    // }, [errors, saveStatus, watchedValues]);

    useEffect(() => {
        // console.info('initial state', initialValues);
        setInitialState(initialValues);
    }, []);

    //update dirty state
    useEffect(() => {
        onDirtyChange?.(isDirty);
    }, [isDirty, onDirtyChange]);

    //update changes when watched values change
    useEffect(() => {
        const comparison = { ...watchedValues };
        delete comparison['id'];
        if (
            !isEqual(comparison, changes)
            // JSON.stringify(watchedValues) !== JSON.stringify(changes) && 
            // JSON.stringify(watchedValues) !== JSON.stringify(initialState)
        ) {
            setChanges(comparison);
            onChange?.(comparison);
        }
    }, [watchedValues, onChange, changes, initialState]);

    const isFormChanged = useMemo(() => {
        return JSON.stringify(initialState) !== JSON.stringify(watchedValues);
    }, [initialState, watchedValues]);

    // const debouncedOnChange = useCallback(debounce(onChange, 300), [onChange]);

    // useEffect(() => {
    //     debouncedOnChange?.(watchedValues);
    // }, [watchedValues, debouncedOnChange]);

    const fieldTypeDetails = fieldType[initialValues.type];

    return (
        <>
            <form onSubmit={handleSubmit(handleSave)}>
                <Card key={initialValues.field_id}>
                    <Card.Header
                        title={fieldTypeDetails.title}
                        subtitle={fieldTypeDetails.information}
                    />
                    <Card.Divider />
                    <Card.Content>
                        <Layout gap={6}>

                            {/* <GenericFormField
                            control={control}
                            errors={errors}
                            name="active"
                            label="Enabled"
                            info="Field is enabled. If disabled the form field will not be visible on the test suite input form"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <ToggleSwitch
                                    checked={value}
                                    onChange={(ev) => { console.info(ev, ev.target.checked); onChange(ev.target.checked) }}
                                />
                            )}
                        /> */}

                            <GenericFormField
                                control={control}
                                errors={errors}
                                name="label"
                                label="Field Name"
                                info="The Field Name will be visible in the raytd mobile application and your reports"
                                required
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <Input
                                        type="text"
                                        size='small'
                                        autoFocus={true}
                                        value={value}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        ref={ref}
                                        maxLength={30}
                                        placeholder='Enter a field label'

                                    />
                                )}
                            />

                            {/* <GenericFormField
                            control={control}
                            errors={errors}
                            name="field_name"
                            label="Name"
                            info="This text will be displayed in the raytd mobile app and the interactive report."
                            required
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Input
                                    type="text"
                                    size='small'
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    ref={ref}
                                    placeholder='Enter a field name'

                                />
                            )}
                        /> */}

                            <GenericFormField
                                control={control}
                                errors={errors}
                                name="description"
                                label="Description"
                                info="This information is only visible in the test suite builder."
                                render={({ field: { onChange, value } }) => (
                                    <Input
                                        type="text"
                                        size='small'
                                        value={value}
                                        onChange={onChange}
                                        placeholder='Field description'

                                    />
                                )}
                            />

                            <GenericFormField
                                control={control}
                                errors={errors}
                                name="info"
                                label="Information Popup"
                                info="This text will be available as an information pop-up in the raytd mobile app."
                                render={({ field: { onChange, value } }) => (
                                    <Input
                                        type="text"
                                        size='small'
                                        value={value}
                                        onChange={onChange}
                                        placeholder='Field description'

                                    />
                                )}
                            />

                            {fieldTypeDetails.placeholder && (

                                <GenericFormField
                                    control={control}
                                    errors={errors}
                                    name="placeholder"
                                    label="Placeholder"
                                    info="This text will be displayed as a default within the field for users when they are using the raytd mobile application."
                                    required
                                    render={({ field: { onChange, value } }) => (
                                        <Input
                                            type="text"
                                            size='small'
                                            value={value}
                                            onChange={onChange}
                                            placeholder='Enter field placeholder'

                                        />
                                    )}
                                />

                            )}
                            <div className="hidden">
                                <GenericFormField
                                    control={control}
                                    errors={errors}
                                    name="validations"
                                    label="Validations"
                                    render={({ field: { onChange, value } }) => (
                                        <Input
                                            type="text"
                                            size='small'
                                            value={value}
                                            onChange={onChange}
                                            placeholder='Enter validations'

                                        />
                                    )}
                                />
                            </div>

                            {initialValues.type === 'number' && (
                                <GenericFormField
                                    control={control}
                                    errors={errors}
                                    name="decimal_places"
                                    label="Decimal Places"
                                    info="Select the maximum number of decimal places allowed"
                                    render={({ field: { onChange, value } }) => (

                                        <Dropdown
                                            placeholder="Select"
                                            options={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => ({ id: i, value: i.toString() }))}
                                            selectedId={value}
                                            onSelect={(option) => onChange(option.id)}
                                        />

                                        // <Input
                                        //     type="text"
                                        //     size='small'
                                        //     value={value}
                                        //     onChange={onChange}
                                        //     placeholder='Enter validations'

                                        // />
                                    )}
                                />
                            )}

                            {initialValues.type === 'select' && (
                                <GenericFormField
                                    control={control}
                                    errors={errors}
                                    name="allow_add"
                                    label={'Allow add to list'}
                                    info="Allow users to add to the list using the mobile application in the raytd mobile app."
                                    render={({ field: { onChange, value } }) => (
                                        <ToggleSwitch
                                            checked={value}
                                            onChange={(ev) => { onChange(ev.target.checked) }}
                                        />
                                    )}
                                />

                            )}

                            {initialValues.type === 'select' && (
                                <GenericFormField
                                    control={control}
                                    errors={errors}
                                    name="selection_type"
                                    label={'Selections Allowed'}
                                    info="Users can be allowed to select only one, or multiple, responses from this list."
                                    render={({ field: { onChange, value } }) => (
                                        <Dropdown
                                            placeholder="Select"
                                            options={[
                                                { id: 'single', value: 'Single Selection' },
                                                { id: 'multiple', value: 'Multiple Selections' }
                                            ]}
                                            selectedId={value}
                                            onSelect={(option) => onChange(option.id)}
                                            size="small"
                                        />
                                    )}
                                />

                            )}


                            {fieldTypeDetails.hasData && (
                                <GenericFormField
                                    control={control}
                                    errors={errors}
                                    name="data"
                                    label={fieldTypeDetails.dataEditorLabel ?? "List Data"}
                                    required
                                    info={fieldTypeDetails.dataEditorInfo ?? "This list will provide the user with options to select in the raytd mobile app."}
                                    render={({ field: { onChange, value } }) => (
                                        <DataEditorField
                                            value={value}
                                            onChange={onChange}
                                            onOrderChange={_handleOrderChange}
                                            saveStatus={saveStatus}
                                        />
                                    )}
                                />

                            )}

                            {initialValues.type === 'textarea' && (
                                <GenericFormField
                                    control={control}
                                    errors={errors}
                                    name="data"
                                    label="Quick Fill Responses"
                                    info="This list will provide the user with quick fill responses in the raytd mobile app."
                                    render={({ field: { onChange, value } }) => (
                                        <DataEditorField
                                            value={value}
                                            onChange={onChange}
                                            onOrderChange={_handleOrderChange}
                                            saveStatus={saveStatus}
                                        />
                                    )}
                                />

                            )}
                        </Layout>


                    </Card.Content>
                    <Card.Content>
                        <Box gap={2}>

                            <Button size="small" priority="secondary" type="submit" disabled={!isDirty || saving}>
                                {
                                    saving ? (
                                        <Loader size="tiny" />
                                    ) : ("Save")}
                            </Button>

                            <Box flex={1} />
                            <Button size="small" priority="secondary" skin="destructive" disabled={!isFormChanged} onClick={() => setShowDiscardModal(true)}>
                                Discard
                            </Button>
                        </Box>

                    </Card.Content>
                </Card>
            </form>
            {showDiscardModal && (
                <DiscardChangesModal
                    visible={showDiscardModal}
                    onConfirm={onDelete}
                    onCancel={() => setShowDiscardModal(false)}
                />
            )}
        </>
    )

}

export default CustomFieldEditor;