import { useTestSpecifications } from '@/app/features/testsuites/specifications/Context'
import NestedListSkeleton from '@/components/raytd/nested-list-loading-state'
import { Button } from "@/components/ui/button"
import { TooltipProvider } from '@/components/ui/tooltip'
import { Category } from './Category'

export default function NestedList() {

    const { groupedData, isLoading } = useTestSpecifications();

    const addTestSpecification = () => {
        // Logic to add a new test specification
    }

    if (isLoading) {
        return <NestedListSkeleton />
    }

    return (
        <TooltipProvider>
            <div className="w-full p-4">
                <div className="flex justify-between items-center mb-4">
                    <h1 className="text-2xl font-bold">Tests</h1>
                    <Button onClick={addTestSpecification}>+ Add Test Specification</Button>
                </div>
                {Object.entries(groupedData)?.map(([category, subCategories]) => (
                    <Category
                        key={category}
                        category={category}
                        subCategories={subCategories}
                    />
                ))}
            </div>
        </TooltipProvider>
    )
}
