import { useParams } from "react-router-dom";
import { useGetTestSuiteQuery, useUpdateTestSuiteMutation } from "@app.raytd.com/store";
import { toast } from 'sonner';
import TestSuiteDetailsForm from "./DetailsForm";
import LoadingState from '@/components/raytd/loading-state';

const DetailsPage: React.FC = () => {

    const { testSuiteId } = useParams<{ testSuiteId: string }>();
    const { data: suite, isLoading } = useGetTestSuiteQuery(testSuiteId);
    const [updateTestSuite, { isLoading: isUpdating }] = useUpdateTestSuiteMutation();

    const handleSubmit = async (values: any) => {
        console.debug('save test suite', values, suite);
        try {
            await updateTestSuite({ id: testSuiteId, 
                title: values.name, 
                description: values.description
             }).unwrap();
            toast.success('Test suite updated successfully');
        } catch (error) {
            console.error(error);
            toast.error('Failed to update test suite');
        }
    }

    if (isLoading) {
        return <LoadingState />
    }

    return (
        <div className="max-w-2xl">
            <TestSuiteDetailsForm
                suite={suite}
                onSubmit={handleSubmit}
                isBusy={isUpdating}
            />
        </div>
    )
}


export default DetailsPage;