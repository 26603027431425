import { DefaultFieldProperties, TestSuiteType } from "store/src/lib/tests/entity";
import { ChevronRightIcon } from '@heroicons/react/24/solid';

export type ToolActionContext = {
    setActiveTab: (tab: number) => void;
}



// export const locationFields: DefaultFieldProperties[] = [
//     {
//         field_name: 'asset',
//         label: 'Asset Name',
//         description: 'Select from your available Assets',
//         required: true,
//         active: true,
//     }, {
//         field_name: 'building',
//         label: 'Building Name',
//         description: 'Select from your available Buildings',
//         required: true,
//         active: true,
//     }, {
//         field_name: 'floor',
//         label: 'Building Level',
//         description: 'Select Level',
//         required: true,
//         active: true,
//     }, {
//         field_name: 'room',
//         label: 'Area / Room',
//         description: 'Select Area / Room',
//         required: true,
//         active: true,
//     }
// ]

export const locationFields: DefaultFieldProperties[] = [
    {
        field_name: 'asset',
        label: 'Asset Name',
        description: 'Select from your available Assets',
        type: 'raytd',
        required: true,
        active: true,
    }, {
        field_name: 'location',
        field_id: 'location',
        label: 'Location',
        description: 'Select from your available Locations',
        type: 'raytd',
        required: true,
        required_configurable: true,
        active: true,
        active_configurable: true,
    }, {
        field_name: 'element',
        field_id: 'element',
        label: 'Element',
        description: 'Select from your element classification list',
        type: 'raytd',
        required: true,
        required_configurable: true,
        active: true,
        active_configurable: true,
    }, {
        field_name: 'element_id',
        label: 'Element Identifier',
        description: 'Enter Element Identifier',
        required: false,
        required_configurable: true,
        active: true,
        active_configurable: true,
        configurable: true,
    }, {
        field_name: 'element_name',
        label: 'Element Name',
        description: 'Enter Element Name',
        required: false,
        required_configurable: true,
        active: true,
        active_configurable: true,
        configurable: true,
    }
]

const observationFieldBuilder = (testSuiteType: TestSuiteType): DefaultFieldProperties => {
    return {
        field_name: 'observations',
        label: 'Observations',
        description: 'Enter Observations',
        required: true,
        required_configurable: true,
        active: true,
        active_configurable: testSuiteType !== 'compliance',
        configurable: true,
    }
}

const ratingFieldBuilder = (testSuiteType: TestSuiteType) => {
    return {
        field_name: 'rating',
        label: 'Rating',
        description: 'Enter Rating',
        required: true,
        required_configurable: true,
        active: testSuiteType !== 'generic',
        configurable: false,
        testSuiteType
    }
}

const recommendationFieldBuilder = (testSuiteType: TestSuiteType) => {
    return {
        field_name: 'recommendations',
        label: 'Recommendations',
        description: 'Enter Recommendations',
        required: true,
        required_configurable: true,
        active: true,
        active_configurable: testSuiteType !== 'compliance',
        configurable: true,
        testSuiteType
    }
}

const photographFieldBuilder = (testSuiteType: TestSuiteType) => {
    return {
        field_name: 'photographs',
        label: 'Photographs',
        description: 'Add Photographs',
        required: true,
        required_configurable: true,
        active: true,
        active_configurable: testSuiteType !== 'compliance',
        configurable: true,
        testSuiteType
    }
}

// const assetClassificationFieldBuilder = (testSuiteType: TestSuiteType): DefaultFieldProperties => {
//     return {
//         field_name: 'asset_classification',
//         label: 'Asset Classification',
//         description: 'Select Asset Classification',
//         required: true,
//         required_configurable: true,
//         active: true,
//         active_configurable: true,
//         configurable: true,
//     }
// }

// const assetNameFieldBuilder = (testSuiteType: TestSuiteType): DefaultFieldProperties => {
//     return {
//         field_name: 'asset_name',
//         label: 'Asset Name',
//         description: 'Enter Asset Name',
//         required: true,
//         required_configurable: true,
//         active: true,
//         active_configurable: true,
//         configurable: true,
//     }
// }

// const assetIdentifierFieldBuilder = (testSuiteType: TestSuiteType): DefaultFieldProperties => {
//     return {
//         field_name: 'asset_identifier',
//         label: 'Asset Identifier',
//         description: 'Enter Asset Identifier',
//         required: true,
//         required_configurable: true,
//         active: true,
//         active_configurable: true,
//         configurable: true,
//     }
// }

export const testSuiteTypeSpecificFields: Record<TestSuiteType, DefaultFieldProperties[]> = {
    compliance: [
        {
            field_name: 'test',
            label: 'Test',
            description: 'Select Test',
            required: true,
            active: true,
            configurable: true,
            toolAction: {
                text: 'Configure Tests',
                icon: <ChevronRightIcon width={18} height={18} />,
                action: (context: ToolActionContext) => { context.setActiveTab(4)}
            }
        },

        observationFieldBuilder('compliance'),
        recommendationFieldBuilder('compliance'),
        photographFieldBuilder('compliance')
    ],
    condition: [
//        assetClassificationFieldBuilder('condition'),
  //      assetNameFieldBuilder('condition'),
    //    assetIdentifierFieldBuilder('condition'),
        ratingFieldBuilder('condition'),
        observationFieldBuilder('condition'),
        recommendationFieldBuilder('condition'),
        photographFieldBuilder('condition')
    ],
    generic: [
      //  assetClassificationFieldBuilder('condition'),
        //assetNameFieldBuilder('condition'),
        //assetIdentifierFieldBuilder('condition'),
        photographFieldBuilder('condition')
    ]
}

