import * as React from 'react'
import { Card, CardContent } from "@/components/ui/card"
import { cn } from "@/lib/utils"
import { Badge } from "@/components/ui/badge"
import { Pill } from '@/components/raytd/pill'
import Rating from '@/app/features/reports/Rating'
import { useGetRatingScalesQuery } from '@app.raytd.com/store'
import { RatingScale } from 'store/src/lib/tests/entity'
import { RatingTypes } from '@/app/features/reports/types'

interface RatingOption {
    label: string
    value: number
    description: string
}

interface RatingType {
    id: number;
    name: string;
    type: RatingTypes;
    options: RatingOption[]
    rateable: boolean
    selected?: boolean
}

interface RatingCirclesProps {
    value: number
    maxValue: number
}

const RatingCircles = ({ value, maxValue }: RatingCirclesProps) => {
    return (
        <div className="flex gap-1 my-2">
            {Array.from({ length: maxValue }).map((_, i) => (
                <div
                    key={i}
                    className={cn(
                        "w-4 h-4 rounded-full border",
                        i + 1 <= value
                            ? "bg-primary border-primary"
                            : "bg-background border-muted"
                    )}
                />
            ))}
        </div>
    )
}

interface RatingTypeRowProps {
    type: RatingType
    onSelect: (typeId: number) => void
}

const EmptyCard: React.FC<RatingCardProps> = ({ index, type, option, maxValue, onSelect }) => {
    return (
        <Card
            key={index}
            className={cn(
                "cursor-pointer transition-all hover:border-primary",
                "opacity-50"
            )}
            onClick={() => onSelect(type.id)}
        >
            <CardContent className="p-4">

            </CardContent>
        </Card>
    );
}


type RatingCardProps = {
    index: number;
    type: RatingType;
    option?: { label: string; description: string; modifications?: string };
    maxValue: number;
    onSelect: (id: number) => void;
};

const RatingCard: React.FC<RatingCardProps> = ({ index, type, option, maxValue, onSelect }) => {
    return (
        
        <Card
            key={index}
            className={cn(
                "cursor-pointer transition-all h-80",
                type.selected && "border-primary",
                !option && "opacity-50",
                {
                    "border-compliance-500": type.type.includes("compliance"),
                    "border-condition-500": type.type.includes("condition"),
                    "border-zinc-900": type.type.includes("general"),
                }
            )}
            onClick={() => onSelect(type.id)}
        >
            <CardContent className="p-4">
                <div className="flex justify-between items-center mb-2">
                    <div className="font-medium">{option?.label || `Rating ${index + 1}`}</div>
                    <Pill variant='outline' size="sm">
                        {(index + 1).toFixed(2)}
                    </Pill>
                </div>
                {/* <RatingCircles value={index + 1} maxValue={maxValue} /> */}
                <Rating selected={index} type={type.type} width={120} height={40} />


                <p className="text-sm text-muted-foreground mb-4">
                    {option.description}
                </p>


            </CardContent>
        </Card>
    );
};


const RatingTypeRow = ({ type, onSelect }: RatingTypeRowProps) => {
    const maxValue = Math.max(...type.options.map(o => o.value))

    return (
        <div className={cn(
            "py-4 px-3 border-t-2 border-zinc-200",
            {
                "bg-zinc-100": type.selected,
            },
        )}>
            <div className="flex justify-between items-center mb-4">
                <h3 className="text-base font-medium">{type.name}</h3>
                <Pill
                    variant={type.selected ? "inverted" : "enabled"}
                    onClick={() => onSelect(type.id)}
                    className="cursor-pointer w-24 justify-center"
                >
                    {type.selected ? "Selected" : "Select"}
                </Pill>
            </div>

            {type.rateable ? (
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4">
                    {Array.from({ length: maxValue }).map((_, index) => {
                        const option = type.options.find(o => o.value === index + 1)

                        if (!option) {
                            return <EmptyCard index={index} type={type} option={option} maxValue={maxValue} onSelect={onSelect} />
                        }

                        return (
                            <RatingCard
                                key={index}
                                index={index}
                                type={type}
                                option={option}
                                maxValue={maxValue}
                                onSelect={onSelect}
                            />
                        );
                    })}
                </div>
            ) : (
                <div className="text-muted-foreground py-8">
                    This rating type is not rateable
                </div>
                )}
        </div>
    )
}

interface RatingSelectorProps {
    types?: RatingType[]
    onTypeSelect?: (typeId: number) => void
}

const generateOptions = (scale: any): RatingOption[] => {
    const options: RatingOption[] = [];
  
    if (scale.very_poor) {
      options.push({
        label: "Not Compliant",
        value: 1,
        description: scale.very_poor,
      });
    }
  
    if (scale.poor) {
      options.push({
        label: "Not Compliant",
        value: 2,
        description: scale.poor,
      });
    }
  
    if (scale.fair) {
      options.push({
        label: "Fair",
        value: 3,
        description: scale.fair,
      });
    }
  
    if (scale.good) {
      options.push({
        label: "Compliant",
        value: 4,
        description: scale.good,
      });
    }
  
    if (scale.very_good) {
      options.push({
        label: "Very Compliant",
        value: 5,
        description: scale.very_good,
      });
    }
  
    return options;
  };
  

function transformRatingScales(ratingScales: RatingScale[]): RatingType[] {
    return ratingScales.map(scale => {

        return ({
        id: scale.id,
        type: scale.type as unknown as RatingTypes,
        name: scale.label,
        selected: true,
        rateable: scale.rateable,
        options: generateOptions(scale),
    })});
}


export default function Component({
    onTypeSelect
}: RatingSelectorProps) {

    const { data: ratingScales, isLoading: ratingScalesLoading } = useGetRatingScalesQuery({});

    const types = React.useMemo(() => {
        if (ratingScales) {
            return transformRatingScales(ratingScales);
        }

        return [];
    }, [ratingScales]);

    console.debug('ratingScales', { ratingScales, ratingScalesLoading });

    const [selectedType, setSelectedType] = React.useState<number | null>(
        types.find(t => t.selected)?.id || null
    );

    const handleTypeSelect = (typeId: number) => {
        setSelectedType(typeId);
        onTypeSelect?.(typeId);
    };

    const typesWithSelection = types.map(type => ({
        ...type,
        selected: selectedType === type.id,
    }));

    return (
        <div className="w-full mt-4 gap-4">
            {typesWithSelection.map((type) => (
                <RatingTypeRow
                    key={type.id}
                    type={type}
                    onSelect={handleTypeSelect}
                />
            ))}
        </div>
    )
}