import React, { useState } from 'react'
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
} from "@/components/ui/table"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { MoreHorizontal, Archive, Copy, LayoutGrid, FlaskConical, Clipboard } from 'lucide-react'
import ValuePill from '../../../../ui/src/lib/badges/ValuePill';
import { useGetTestSuitesQuery } from '@app.raytd.com/store'
import { Test } from '../../../../dist/store/src/lib/assessment/result';
import TestTypePill from '@/components/raytd/test-type-pill'
import classNames from 'classnames'
import Filters from '@/app/features/assets/Filters'
import { MainPage } from '@/app/layout2/AppLayout'
import SubPageLayout from '@/app/features/organisation/SubPageLayout'
import { useNavigate } from 'react-router-dom'
import { compare } from 'natural-orderby'
import { SkeletonLoader } from '@/app/features/assets/Skeleton'
import OrganisationAvatar from '@/components/raytd/organisation-avatar'

const CategoriesPill = ({ item }) => {

    if (item.categories === 0 && item.subCategories === 0) {
        return null;
    }

    return (
        <div className="
            flex flex-row flex-wrap space-x-1.5 items-center justify-center text-xs bg-zinc-100 px-2 py-1.5 rounded-md h-full"
            title='Test Categories and Subcategories'
        >
            <LayoutGrid className="h-4 w-4 text-zinc-500" />
            <span>{item.categories}</span>
            <span className="px-0.5 opacity-50">|</span>
            <span>{item.subCategories}</span>
        </div>

    )
}

const TestSuitesPage: React.FC = () => {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('')
    const [filter, setFilter] = useState('active');

    const { data: testSuites, isLoading, isFetching } = useGetTestSuitesQuery({})

    console.debug('testSuites', testSuites)

    const filteredTestSuites = testSuites?.filter(suite =>
        (suite.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            suite.description.toLowerCase().includes(searchTerm.toLowerCase())) &&
        (filter === 'all' || suite.status === filter.toLocaleLowerCase())
    ).sort((a, b) => compare()(a.title, b.title));

    const handleCreateTestSuite = () => {
        navigate('/test-suites/new')
    }

    return (

        <>
            <div className="flex flex-row justify-end items-center space-x-4">
                <Filters selected={filter} setFilter={setFilter} setSearchTerm={setSearchTerm} />
                <Button className="" variant="default" onClick={handleCreateTestSuite}>+ Create Test Suite</Button>
            </div>

            <MainPage>
                <SubPageLayout
                    title="Test Suites"
                    subtitle='Create, update, manage and share your test suites.'
                    showSpinner={isFetching}
                >
                    {isLoading ? (
                        <SkeletonLoader />
                    ) : (
                        <TestSuiteTable data={filteredTestSuites} />
                    )}

                </SubPageLayout>
            </MainPage>
        </>
    )
}

const TestSuiteTable = ({ data }) => {

    const navigate = useNavigate();

    const handleClick = (suite) => {
        navigate(`/test-suites/${suite.id}`)
    }

    return (
        <Table>
            <TableBody>
                {data?.map((suite) => (
                    <TableRow key={suite.id} className={classNames({ 'opacity-70': suite.status === 'archived' })}>
                        <TableCell className='w-12'>
                            <div className='flex flex-wrap'>
                                <TestTypePill type={suite.type} inverse={suite.status === 'archived'} />
                            </div>
                        </TableCell>
                        <TableCell>
                            <Button variant='ghost' onClick={() => handleClick(suite)} asChild>
                            <div className="flex items-center space-x-4 cursor-pointer group">
                                <div>
                                    <div className="font-semibold group-hover:underline">{suite.title}</div>
                                    <div className="text-sm text-gray-500">{suite.description}</div>
                                </div>
                            </div>
                            </Button>
                        </TableCell>
                        <TableCell>
                            <CategoriesPill item={suite} />
                        </TableCell>
                        <TableCell>
                            <ValuePill label="Tests" value={suite.tests} iconOnly icon={<FlaskConical className='text-zinc-500 h-4 w-4' />} />
                        </TableCell>
                        <TableCell>
                            <ValuePill label="Inspections" value={suite.inspections} iconOnly icon={<Clipboard className="text-zinc-500 h-4 w-4" />} />
                        </TableCell>
                        <TableCell>
                            <OrganisationAvatar organisation={suite.organisation} size='sm' />
                        </TableCell>

                        <TableCell>
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <Button variant="ghost" className="h-8 w-8 p-0">
                                        <MoreHorizontal className="h-4 w-4" />
                                    </Button>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent align="end">
                                    <DropdownMenuItem>
                                        <Archive className="mr-2 h-4 w-4" />
                                        Archive
                                    </DropdownMenuItem>
                                    <DropdownMenuItem>
                                        <Copy className="mr-2 h-4 w-4" />
                                        Duplicate
                                    </DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}

export default TestSuitesPage;