import CustomFieldErrorBoundary from '@/app/features/testsuites/v2/CustomFieldErrorBoundary'
import ExpandButton from '@/components/raytd/expand-button'
import { Pill } from '@/components/raytd/pill'
import { Button } from "@/components/ui/button"
import { TableCell, TableRow } from "@/components/ui/table"
import { cn } from '@/lib/utils'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { CopyIcon, DeleteIcon, EllipsisIcon, GripVertical, MoreHorizontalIcon, Plus } from 'lucide-react'
import React from 'react'
import { CustomField } from 'store/src/lib/tests/entity'
import ActiveSelect from './ActiveSelect'
import CustomFieldEditor from './CustomFieldEditor'
import HandleTableCell from './HandleTableCell'
import { fieldType } from './types'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'

type CustomFieldsOptionsDropdownProps = {
    onDuplicate: () => void;
    onDelete: () => void;
}

const CustomFieldOptionsDropdown = React.memo(({
    onDuplicate,
    onDelete
}: CustomFieldsOptionsDropdownProps) => {
    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button
                    variant="outline"
                    size="sm"
                >
                    <MoreHorizontalIcon className="h-3 w-3 text-zinc-500" />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
                <DropdownMenuItem onClick={() => onDuplicate()}>
                    <CopyIcon className="h-3 w-3 mr-2" />Duplicate
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem className="text-red-700" onClick={() => onDelete()}>
                    <DeleteIcon className="h-3 w-3 mr-2 text-red-700" />Delete
                </DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    );

});

interface SortableCustomFieldProps {
    field: CustomField;
    updateCustomField: (fieldId: string, updates: Partial<CustomField>) => void;
    removeCustomField: (fieldId: string) => void;
    onDuplicateField: (fieldId: string) => void;
    toggleExpand: (fieldId: string) => void;
    isExpanded: boolean;
    isDirty: boolean;
}

const SortableCustomField: React.FC<SortableCustomFieldProps> = ({
    field,
    updateCustomField,
    removeCustomField,
    onDuplicateField,
    toggleExpand,
    isExpanded,
    isDirty
}) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: field.field_id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <>
            <TableRow ref={setNodeRef} style={style} className={cn({ 'bg-zinc-50 border-t border-x': isExpanded })}>
                <HandleTableCell>
                    <div className="flex items-center">
                        <span {...attributes} {...listeners} className="mr-2 cursor-move">
                            <GripVertical className="text-muted-foreground" />
                        </span>
                    </div>
                </HandleTableCell>
                <TableCell className="w-[16px]"></TableCell>
                <TableCell className="w-[200px]">
                    <div className="flex items-center font-medium">
                        {field.label}
                    </div>
                </TableCell>
                <TableCell className="w-[250px]"><span className="truncate">{field.description}</span></TableCell>
                <TableCell className="w-[calc(100%-600px)]">
                    <div className="flex flex-wrap justify-end space-x-2">
                        {isDirty && (<Pill variant="outline" size="sm">Modified</Pill>)}
                        <Pill className="truncate" variant='enabled' size="sm">{fieldType[field.type]?.title ?? field.type}</Pill>
                    </div>
                </TableCell>
                <TableCell className="w-[80px]">

                    <ActiveSelect
                        value={field.active}
                        onValueChange={(value) => updateCustomField(field.field_id, { active: value })}
                    />

                </TableCell>
                <TableCell className="w-[80px]">

                    <ActiveSelect
                        value={field.required}
                        onValueChange={(value) => updateCustomField(field.field_id, { required: value })}
                    />

                </TableCell>
                <TableCell className="w-[12px] p-0">
                    <CustomFieldOptionsDropdown 
                        onDuplicate={() => onDuplicateField(field.field_id)}
                        onDelete={() => removeCustomField(field.field_id)}
                    />
                </TableCell>
                <TableCell className="w-[12px] p-0">

                    <Button
                        variant="ghost"
                        size="sm"
                        className='p-2'
                        onClick={() => toggleExpand(field.field_id)}

                    >
                        <ExpandButton variant="item" expanded={isExpanded} className="ml-1" />
                    </Button>


                </TableCell>
            </TableRow>

            {isExpanded && (
                <TableRow className="bg-zinc-50 border-x border-b-2 border-b-zinc-300 rounded-lg">
                    <TableCell colSpan={9}>
                        <div className="p-4 space-y-4 max-w-screen-md">
                            <CustomFieldErrorBoundary>
                                {/* @ts-expect-error */}
                                <CustomFieldEditor
                                    customField={field}
                                    initialValues={field}
                                    onChange={(updatedField) => updateCustomField(field.field_id, updatedField)}
                                    onRemove={(field) => removeCustomField(field.field_id)}
                                />
                            </CustomFieldErrorBoundary>
                        </div>
                    </TableCell>
                </TableRow>
            )}

        </>
    );
};

export default SortableCustomField;