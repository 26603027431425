import { useBreadcrumb } from '@/app/layout2/BreadcrumbContext';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const formatPathName = (name: string): string => {
  return name
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const convertRouteToFriendlyName = (route: string): string => {
  return route
    .split('/')
    .filter(segment => segment) // Remove empty segments
    .map(segment => segment.charAt(0).toUpperCase() + segment.slice(1)) // Capitalize first letter
    .join('-'); // Join with a delimiter
};

const BreadcrumbNavigation: React.FC = () => {
  const location = useLocation();
  const { getMatchingPaths } = useBreadcrumb();
  const pathnames = location.pathname.split('/').filter((x) => x);

  const paths = pathnames.map((_, index) => '/' + pathnames.slice(0, index + 1).join('/'));
  const matchingPaths = getMatchingPaths(paths);


  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-1 md:space-x-3">
        {location.pathname === '/' && (
          <li className="inline-flex items-center">
            <Link
              to="/"
              className="inline-flex items-center text-sm font-normal text-zinc-800 hover:text-blue-600"
            >
              Dashboard
            </Link>
          </li>
        )}
        {pathnames.map((name, index) => {
          const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
          const isLast = index === pathnames.length - 1;
          const displayName = matchingPaths[routeTo] || formatPathName(name);
          const friendlyName = convertRouteToFriendlyName(routeTo);


          console.debug('Breadcrumb', { name, routeTo, displayName });

          return (
            <li key={friendlyName}>
              <div className="flex items-center">
                <Separator />
                <Link
                  to={routeTo}
                  className={`ml-1 text-sm font-medium md:ml-2 ${isLast
                    ? 'text-zinc-950 cursor-default font-semibold'
                    : 'text-zinc-950 font-normal hover:text-blue-600'
                    }`}
                  aria-current={isLast ? 'page' : undefined}
                >
                  {displayName}
                </Link>
              </div>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

const Separator: React.FC = () => {
  return (
    <span className='font-normal text-zinc-700 text-sm'>/</span>
  );
}

export default BreadcrumbNavigation;
