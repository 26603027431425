import { cva, type VariantProps } from "class-variance-authority"

const testTypePillVariants = cva(
  "px-3 py-1.5 rounded-md text-xs font-medium border",
  {
    variants: {
      type: {
        compliance: "bg-green-500 text-white",
        generic: "",
        condition: "",
        numerical: "bg-pink-400 text-white",
      },
      inverse: {
        true: "bg-transparent",
      }
    },
    compoundVariants: [
      {
        type: "compliance",
        inverse: true,
        className: "text-green-500 border-green-500 bg-white",
      },
      {
        type: "generic",
        inverse: false,
        className: "text-white bg-zinc-500 border-zinc-500"
      },
      {
        type: "generic",
        inverse: true,
        className: "text-zinc-500 bg-white border-zinc-500"
      },
      {
        type: "condition",
        inverse: true,
        className: "bg-white text-sky-500 border-sky-500",
      },
      {
        type: "condition",
        inverse: false,
        className: "bg-sky-500 text-white border-transparent",
      },
      {
        type: "numerical",
        inverse: true,
        className: "bg-white text-pink-400 border-pink-400",
      },
    ],
    defaultVariants: {
      type: "generic",
      inverse: false,
    },
  }
)

export type TestType = "compliance" | "generic" | "condition" | "numerical"

export const getTypeName = (type: TestType): string => {
  const names: Record<TestType, string> = {
    compliance: "Compliance",
    generic: "General",
    condition: "Condition",
    numerical: "Numerical",
  }
  return names[type] || "Unknown"
}

interface TestTypePillProps extends VariantProps<typeof testTypePillVariants> {
  type: TestType
  inverse?: boolean
}

export default function TestTypePill({ type, inverse = false }: TestTypePillProps) {
  return (
    <div className={testTypePillVariants({ type, inverse })}>
      {getTypeName(type)}
    </div>
  )
}