import React, { useCallback, useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import {
    ColumnDef,
    ColumnMeta,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'

import { Archive, Copy, MoreHorizontal, Plus } from 'lucide-react'

import { Button } from '@/components/ui/button'

import { Input } from '@/components/ui/input'

import {
    Table,
    TableBody,
    TableCell,
    TableRow,
} from '@/components/ui/table'
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'

import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'

import { MainPage } from '@/app/layout2/AppLayout'
import SubPageLayout from '@/app/features/organisation/SubPageLayout'
import Filters from '@/app/features/assets/Filters'
import { useTableStatusFilter } from '@/hooks/use-table-status-filter'
import { SkeletonLoader } from '@/app/features/assets/Skeleton'
import OrganisationAvatar from '@/components/raytd/organisation-avatar'
import EmptyState from '@/components/raytd/empty-state'
import { toast } from 'sonner'
import ArchiveRestoreMenuItem from '@/components/raytd/archive-restore-menu-item'
import classNames from 'classnames'
import DataTablePagination from '@/app/features/assets/TablePagination'
import { CompanyEntity, useArchiveReportBlockForUserMutation, useArchiveReportBlockMutation, useDuplicateReportBlockMutation, useGetReportBlocksQuery } from '@app.raytd.com/store'
import { ReportBlock } from 'store/src/lib/services/types'

// interface ReportBlock {
//     id: string
//     name: string
//     description: string
//     status: 'active' | 'archived'
//     organisation: CompanyEntity
    
// }

interface ExtendedColumnMeta extends ColumnMeta<ReportBlock, unknown> {
    cellClassName?: string;
}

export default function ReportBlocks() {
   const navigate = useNavigate()

    //const [reportBlocks, setReportBlocks] = useState<ReportBlock[]>(mockReportBlocks)
    const {data : reportBlocks, isLoading} = useGetReportBlocksQuery();
    const [archiveGroupForUser] = useArchiveReportBlockForUserMutation();
    const [archiveGroup] = useArchiveReportBlockMutation();
    const [duplicateGroup] = useDuplicateReportBlockMutation();

    const { filter, setFilter, statusFilter, setStatusFilter, statusColumnFilterFn, searchTerm, setSearchTerm, globalFilterFn } = useTableStatusFilter()

    const [columnFilters, setColumnFilters] = useState([])


    const handleItemClick = (reportBlock: ReportBlock) => {
       navigate(`/report-blocks/${reportBlock.id}`)
    }

    useEffect(() => {
        setColumnFilters([{ id: 'status', value: statusFilter }])
    }, [statusFilter])

    const handleArchiveForUser = useCallback(async (item) => {
        console.log('Archive', item.id);
        const changeToStatus = item.status === 'active' ? 'archived' : 'restored';
        try {
            await archiveGroupForUser(item.id).unwrap();
            toast.success(`Item ${changeToStatus} successfully`);
        } catch (error) {
            console.error('Error archiving report block', error);
            toast.error(`Item was not ${changeToStatus}`);
        }
    }, []);

    const handleArchive = useCallback(async (item) => {
        console.log('Archive', item.id);
        const changeToStatus = item.status === 'active' ? 'archived' : 'restored';
        try {
            await archiveGroup(item.id).unwrap();
            toast.success(`Item ${changeToStatus} successfully`);
        } catch (error) {
            console.error('Error archiving report block', error);
            toast.error(`Item was not ${changeToStatus}`);

        }
    }, []);

    const handleDuplicate = useCallback(async (item) => {
        console.log('Duplicate', item.id);
        try {
            const response = await duplicateGroup(item).unwrap();
            console.log('Response', response);
            toast.success(`Item duplicated successfully`);
        } catch (error) {
            console.error('Error duplicating report block', error);
            toast.error(`Item was not duplicated`);
        }
    }, []);

    const columns: ColumnDef<ReportBlock>[] = [
        {
            accessorKey: 'avatar',
            size: 50,
            meta: {
                cellClassName: 'w-10',
            },
            cell: ({ row }) => (
                <OrganisationAvatar organisation={row.original.organisation} size='md' />
            ),
        },
        {
            accessorKey: 'name',
            header: 'Report Blocks',
            cell: ({ row }) => (
                <Button variant="ghost" onClick={() => handleItemClick(row.original)} className="px-4">
                    <div className="flex flex-col items-start">
                        <div className="font-semibold">{row.original.name}</div>
                        <div className="font-normal">
                            {row.original.description}
                        </div>
                    </div>
                </Button>
            ),
        },
        {
            accessorKey: 'status',
            filterFn: statusColumnFilterFn,
        },
        {
            id: 'actions',
            meta: {
                cellClassName: 'w-10',
            },
            cell: ({ row }) => (
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button variant="ghost" className="h-8 w-8 p-0">
                            <MoreHorizontal className="h-4 w-4" />
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                        <DropdownMenuItem onClick={() => handleDuplicate(row.original.id)}>
                            <Copy className="mr-2 h-4 w-4" />
                            Duplicate
                        </DropdownMenuItem>
                        <DropdownMenuItem onClick={() => handleArchiveForUser(row.original)}>
                            <ArchiveRestoreMenuItem status={row.original.status} />
                        </DropdownMenuItem>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem onClick={() => handleArchive(row.original)}>
                            <ArchiveRestoreMenuItem
                                status={row.original.is_globally_archived ? 'archived' : 'active'}
                                archiveText='Archive (Everyone)'
                                restoreText='Restore (Everyone'
                            />
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            ),
        },
    ]

    const table = useReactTable({
        data: reportBlocks,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onColumnFiltersChange: setColumnFilters,
        state: {
            columnFilters,
            globalFilter: searchTerm,
        },
        onGlobalFilterChange: setSearchTerm,
        globalFilterFn: (row, columnId, filterValue) => {
            const value = row.getValue(columnId) as string
            return value?.toLowerCase().includes(filterValue.toLowerCase()) ?? false
        },
        initialState: {
            columnVisibility: {
                status: false,
            },
        },
    })

    const handleCreate = () => {
       navigate('/report-blocks/new')
    }

    return (
        <>
            <div className="flex flex-row justify-end items-center space-x-4">
                <Filters selected={statusFilter} setFilter={setStatusFilter} setSearchTerm={setSearchTerm} />
                <Button className="" variant="default" onClick={handleCreate}>
                    <Plus className="mr-2 h-4 w-4" /> Create Report Block
                </Button>
            </div>
            <MainPage>
                <SubPageLayout
                    title="Report Blocks"
                    subtitle='Create, update, manage and share your report blocks.'
                    showSpinner={isLoading}
                >
                    <div className="space-y-4">
                        <div className="rounded-md border">
                            <Table>
                                <TableBody>
                                    <ReportBlockTableBody isLoading={isLoading} data={reportBlocks} table={table} columns={columns} />
                                </TableBody>
                            </Table>
                        </div>
                        {!isLoading && (
                            <DataTablePagination table={table} itemsText='report blocks' />
                        )}
                    </div>
                </SubPageLayout>
            </MainPage>
        </>
    )
}

const ReportBlockTableBody = ({ data, table, columns, isLoading }) => {

    const navigate = useNavigate();

    if (isLoading) {
        return <SkeletonLoader />
    }

    if (!data) {
        return null
    }

    if (data.length === 0) {
        return (
            <EmptyState
                title="You have no report blocks."
                description="Create your first report block to get started."
                actionLabel="Create Report Block"
                onAction={() => {
                    navigate('/report-blocks/new')
                }}
            />
        )
    }

    if (table.getFilteredRowModel().rows?.length === 0) {
        return <NoSearchResults columnsLength={columns.length} text="No report blocks found." />
    }

    const rows = table.getRowModel().rows
    return <TableRows rows={rows} />
}

const TableRows = ({ rows }) => (
    <>
        {rows.map((row) => (
            <TableRow
                key={row.id}
                data-state={row.getIsSelected() && 'selected'}
                className={classNames({ 'opacity-60': row.original.status === 'archived' })}
            >
                {row.getVisibleCells().map((cell) => (
                    <TableCell 
                    key={cell.id}
                    className={(cell.column.columnDef.meta as ExtendedColumnMeta)?.cellClassName}
                        style={{
                            maxWidth: cell.column.columnDef.size ? cell.column.columnDef.size : undefined,
                            alignContent: cell.column.columnDef.align,
                        }}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                ))}
            </TableRow>
        ))}
    </>
)

const NoSearchResults = ({ columnsLength, text }) => (
    <TableRow>
        <TableCell colSpan={columnsLength} className="h-24 text-center">
            {text}
        </TableCell>
    </TableRow>
)