import { useGetTestSpecificationsQuery } from '@app.raytd.com/store'
import React, { createContext, useState, useContext, useMemo, useEffect } from 'react'
import { TestCase } from 'store/src/lib/tests/entity'

type TestSpecificationsContextType = {
    data: TestCase[]
    setData: React.Dispatch<React.SetStateAction<TestCase[]>>
    selectedCategory: string | null
    setSelectedCategory: (category: string | null) => void
    selectedSubCategory: string | null
    setSelectedSubCategory: (subCategory: string | null) => void
    groupedData: Record<string, Record<string, TestCase[]>>
    categories: string[]
    subcategories: { category: string; subcategory: string }[]
    addTestSpecification: () => void;
    isLoading: boolean;
}

const TestSpecificationsContext = createContext<TestSpecificationsContextType | undefined>(undefined)

export function TestSpecificationsProvider({ children, testSuiteId }: { children: React.ReactNode, testSuiteId: string; }) {

    const { data: specifications, isLoading } = useGetTestSpecificationsQuery(testSuiteId);

    const [data, setData] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState<string | null>(null)
    const [selectedSubCategory, setSelectedSubCategory] = useState<string | null>(null)

    const groupedData = useMemo(() => groupByCategory(data), [data])
    const categories = useMemo(() => Object.keys(groupedData), [groupedData])
    //   const subcategories = useMemo(() => 
    //     selectedCategory ? Object.keys(groupedData[selectedCategory]) : []
    //   , [groupedData, selectedCategory])

    const subcategories = useMemo(() => {

        const uniquePairs = new Set<string>();

        Object.keys(groupedData).forEach(category => {
            Object.keys(groupedData[category]).forEach(subcategory => {
                uniquePairs.add(`${category},${subcategory}`);
            });
        });

        return Array.from(uniquePairs).map(pair => {
            const [category, subcategory] = pair.split(',');
            return { category, subcategory };
        });
    }, [groupedData]);

    const addTestSpecification = () => {
        // Logic to add a new test specification
    }

    useEffect(() => {
        if (!isLoading) {
            setData(specifications)
        }
    }, [specifications, isLoading]);

    const value = {
        data,
        setData,
        selectedCategory,
        setSelectedCategory,
        selectedSubCategory,
        setSelectedSubCategory,
        groupedData,
        categories,
        subcategories,
        addTestSpecification,
        isLoading
    }

    return (
        <TestSpecificationsContext.Provider value={value}>
            {children}
        </TestSpecificationsContext.Provider>
    )
}

export function useTestSpecifications() {
    const context = useContext(TestSpecificationsContext)
    if (context === undefined) {
        throw new Error('useNestedList must be used within a NestedListProvider')
    }
    return context
}

function groupByCategory(data: TestCase[]) {
    return data.reduce((acc, item) => {
        if (!acc[item.category]) {
            acc[item.category] = {}
        }
        if (!acc[item.category][item.subcategory]) {
            acc[item.category][item.subcategory] = []
        }
        acc[item.category][item.subcategory].push(item)
        return acc
    }, {} as Record<string, Record<string, TestCase[]>>)
}