import { AssetForm } from "@/app/features/assets/AssetDetails";
import SubPageLayout from "@/app/features/organisation/SubPageLayout"
import PageWithTopBarNavigation from "@/app/features/profile/PageWithTopBarNavigation";
import { TopNavBar } from "@/app/features/profile/TopNavBar";
import { MainPage } from "@/app/layout2/AppLayout";
import { SquareChevronLeft } from "lucide-react";
import { NavLink } from "react-router-dom";

const NewAssetPage = () => {

    return (
        <MainPage>
            <PageWithTopBarNavigation>
                <TopNavBar>
                    <NavLink to="/assets"><SquareChevronLeft className="h-6 w-6 text-zinc-500" /></NavLink>
                </TopNavBar>

                <AssetForm 
                title='Create Asset'
                asset={{
                    id:null,
                    name: '',
                    description: '',
                    status: 'active',


                }} />

            </PageWithTopBarNavigation>
        </MainPage>
    );

}

export default NewAssetPage;