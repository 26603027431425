import TestSpecificationForm from "@/app/features/testsuites/specifications/TestSpecificationForm"
import ExpandButton from "@/components/raytd/expand-button"
import FastFillCountPill from "@/components/raytd/fast-fill-count-pill"
import ListButton from "@/components/raytd/list-button"
import { Pill } from "@/components/raytd/pill"
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible"
import { Input } from "@/components/ui/input"
import { cn } from "@/lib/utils"
import { TestSpecificationsEntity } from "@app.raytd.com/store"
import { CornerDownRight } from "lucide-react"
import { useCallback, useState } from "react"

type TestProps = {
    test: TestSpecificationsEntity
}

/*
export type TestSpecificationsEntity = {
  id: number;
  test_suite_id: number;
  label: string;
  category: string;
  subcategory: string;
  description: string;
  observations: Observations[];
  recommendations: Recommendation[];
  ratingScale: number;
  observations_required: boolean;
  recommendations_required: boolean;
};
*/

export function Test({ test }: TestProps) {
    // console.debug('Test', test);

    const [isExpanded, setIsExpanded] = useState(false);

    const handleToggle = useCallback(() => {
        setIsExpanded(!isExpanded);
    }, [isExpanded, setIsExpanded]);

    return (
        <Collapsible open={isExpanded} onOpenChange={handleToggle} className={cn({ "bg-zinc-100 ml-2": isExpanded })}>
            <div className="mb-2 flex items-center space-x-4">
                <div className="ml-6"><CornerDownRight className="h-4 w-4" /></div>
                <Pill className="flex-shrink-0">{test.label}</Pill>
                <div
                    className="flex-shrink mr-2 max-w-sm text-zinc-600 border-zinc-300 truncate overflow-hidden text-sm border px-2 py-1.5 rounded-md"
                >
                    {test.description ?? ''}
                </div>
                <div className="flex-1" />
                <FastFillCountPill item={{
                    observations_count: test.observations?.length,
                    recommendations_count: test.recommendations?.length
                }} />
                <ListButton variant="ellipsis" />
                <CollapsibleTrigger asChild>
                    <ExpandButton variant="item" expanded={isExpanded} />
                </CollapsibleTrigger>
            </div>
            <CollapsibleContent className="px-2 pb-2">

                <TestSpecificationForm specification={test} onSubmit={() => { }} onDiscard={() => { }} />

            </CollapsibleContent>

        </Collapsible>
    )
}