import PageWithTopBarNavigation from "@/app/features/profile/PageWithTopBarNavigation";
import { NavLink, TopNavBar } from "@/app/features/profile/TopNavBar";
import DetailsPage from "@/app/features/testsuites/DetailsPage";
import TestSpecificationsPage from "@/app/features/testsuites/TestSpecificationsPage";
import { MainPage } from "@/app/layout2/AppLayout";
import { useBreadcrumb } from "@/app/layout2/BreadcrumbContext";
import EmptyState from "@/components/raytd/empty-state";
import { useGetTestSuiteQuery } from "@app.raytd.com/store";
import { useEffect } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import TestSuiteFields from './v2/FieldEditor';
import RatingToolPage from "@/app/features/testsuites/RatingToolPage";

const TestSuiteLayout = () => {
    const { setBreadcrumbName } = useBreadcrumb();
    const { testSuiteId } = useParams<{ testSuiteId: string }>();
    const { data: suite } = useGetTestSuiteQuery(testSuiteId);

    useEffect(() => {
        if (suite) {
            setBreadcrumbName(`/test-suites/${testSuiteId}`, suite?.title);
        }
    }, [suite]);

    console.debug({assetId: testSuiteId});

    return (
        <MainPage>
            <PageWithTopBarNavigation>
                <TopNavBar>
                    <NavLink to="details">
                        Details
                    </NavLink>
                    <NavLink to="fields">
                        Fields
                    </NavLink>
                    <NavLink to="test-specifications">
                        Test Specifications
                    </NavLink>
                    <NavLink to="rating-tool">
                        Rating Tool
                    </NavLink>
                    <NavLink to="collaborators">
                        Collaborators
                    </NavLink>
                </TopNavBar>
                <div className="container mx-auto py-6 px-4">
                    <Routes>
                        <Route path="details" element={<DetailsPage />} />
                        <Route path="fields" element={<TestSuiteFields testSuiteId={testSuiteId}  />} />
                        <Route path="test-specifications" element={<TestSpecificationsPage />} />
                        <Route path="rating-tool" element={<RatingToolPage />} />
                        <Route path="collaborators" element={<EmptyState title="Collaborators" description="Not yet build"  />} />
                        <Route path="*" element={<Navigate to="details" />} />
                    </Routes>
                </div>
            </PageWithTopBarNavigation>
        </MainPage>
    );

}

export default TestSuiteLayout;

