import { CustomField, CustomFieldType } from "store/src/lib/tests/entity"

export interface DataRecord {
  id: string;
  displayName: string;
  ghost?: boolean;
}

type FieldTypeDetails = {
    title: string
    information: string
    value_type: string
    placeholder?: boolean
    hasData?: boolean
    dataEditorLabel?: string
    dataEditorInfo?: string
}


export const fieldType: Partial<Record<CustomFieldType, FieldTypeDetails>> = {
  text: {
    title: 'Short Text',
    information:
      'This field will provide users a single line text box to fill in. Users can input letters, numbers, characters and emojis.',
    value_type: 'string',
  },
  textarea: {
    title: 'Paragraph Text',
    information:
      'This field will provide users with a paragraph sized text box to fill in, plus quick fill responses they can insert. Users can also add their own quick fill responses in the mobile application.',
    value_type: 'string',
  },
  number: {
    title: 'Short Number',
    information:
      'This field will provide users a single line numerical box to fill in. Users can input whole numbers and decimal places.',
    value_type: 'number',
  },
  scrollable_number: {
    title: 'Scrolling Number',
    information:
      'This field will provide users with a scrollable number pad to input numbers.',
    value_type: 'number',
  },
  select: {
    title: 'List',
    information:
      'This field will provide users with a dropdown to select from a list of options.',
    value_type: 'string',
    hasData: true,
    dataEditorLabel: 'Dropdown Options',
    dataEditorInfo: 'This will comprise the selections available.',
  },
  date: {
    title: 'Date (DD/MM/YYYY)',
    information:
      'This field will provide users with a date picker to select a date.',
    value_type: 'date',
  },
  switch: {
    title: 'Toggle (On/Off)',
    information:
      'This field will provide users with a switch to toggle on or off. It will default to off',
    value_type: 'boolean',
    placeholder: false,
  },
  checkbox: {
    title: 'Checkbox (Selected/Blank)',
    information:
      'This field will provide users with a checkbox to select or deselect.',
    value_type: 'boolean',
    placeholder: false,
  },
  radio: {
    title: 'Radio (Selected/Blank)',
    information:
      'This field will provide users with a radio button to select one option from a list.',
    value_type: 'string',
    placeholder: false,
    hasData: true,
    dataEditorLabel: 'Radio Options',
    dataEditorInfo: 'Add options for the radio button',
  },
};

export const fieldTypeConfiguration = fieldType;
