import RatingToolSelector from "./v2/RatingToolSelector";

const RatingToolPage = () => {

    return (
        <div>
            <h1>Rating Tool</h1>
            <RatingToolSelector 
                onTypeSelect={(type) => console.log('type selected', type)}
            />
        </div>
    );

}

export default RatingToolPage;