'use client'

import { zodResolver } from "@hookform/resolvers/zod"
import { debounce, isEqual } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from "react"
import { Controller, useForm } from 'react-hook-form'
import * as z from "zod"

import { Button } from "@/components/ui/button"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card"
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select"
import { Switch } from "@/components/ui/switch"
import { useSubmitWithSaving } from '@app.raytd.com/ui'
import React from 'react'
import { CustomField } from "store/src/lib/tests/entity"
import { ListEditor } from './DataEditorField'
import { DataRecord, fieldType } from './types'
import SegmentedControl from "@/components/raytd/segmented-control"

type CustomFieldEditorProps = {
    customField: CustomField
    initialValues: CustomField
    onSave: (customField: Partial<CustomField>) => Promise<boolean>
    onSuccessfulSave?: (data: CustomField) => void
    onDirtyChange?: (isDirty: boolean) => void
    onChange?: (data: CustomField) => void
    onRemove?: (data: CustomField) => void
}

const formSchema = z.object({
    label: z.string()
        .min(1, "Name is required")
        .max(50, "Name must be less than 50 characters"),
    description: z.string()
        .max(200, "Description must be less than 200 characters"),
    info: z.string(),
    placeholder: z.string().optional(),
    validations: z.string(),
    decimal_places: z.number().optional(),
    allow_add: z.boolean().optional(),
    selection_type: z.enum(['single', 'multiple']).optional(),
    data: z.array(z.object({ id: z.string(), displayName: z.string() })).optional(),
})

export const CustomFieldEditor = ({
    customField,
    initialValues,
    onSave,
    onSuccessfulSave,
    onDirtyChange,
    onChange,
    onRemove,
}: CustomFieldEditorProps) => {

    const { control, handleSubmit, reset, watch, formState: { errors, isDirty } } = useForm<CustomField>({
        resolver: zodResolver(formSchema),
        defaultValues: initialValues
    })

    const [errorMessage, setErrorMessage] = useState('')
    const [saveStatus, setSaveStatus] = useState<'idle' | 'saving' | 'error' | 'success'>('idle')
    const [initialState, setInitialState] = useState<CustomField>(initialValues)
    const [changes, setChanges] = useState<CustomField>(initialValues)
    const [showDiscardModal, setShowDiscardModal] = useState(false)
    const watchedValues = watch()

    useEffect(() => {
        if (customField === undefined) {
            return
        }
        reset(customField, { keepDefaultValues: true })
    }, [customField, reset])

    const onSubmit = useCallback(async (data: CustomField): Promise<boolean> => {
        setSaveStatus('saving')
        if (data.field_name === '') {
            data.field_name = data.label.toLowerCase().replace(/ /g, '_')
        }

        return onSave(data).then((result) => {
            if (result) {
                onSuccessfulSave?.(data)
                setSaveStatus('success')
                reset(data)
                return true
            }
        }).catch((error) => {
            setSaveStatus('error')
            setErrorMessage(error?.message ?? 'Unknown error')
            return false
        })
    }, [onSave, onSuccessfulSave, reset])

    const { handleSubmit: handleSave, saving, error } = useSubmitWithSaving(onSubmit)

    const onDelete = () => {
        console.debug('Discarding changes', initialState)

        const isNewField = initialState.field_name.includes('new_field');

        if (isNewField) {
            onRemove?.(initialState)
        } else {
            reset(initialState)
        }

        // handleSubmit(onSubmit)()
        setShowDiscardModal(false)
    }

    const _handleOrderChange = useCallback((data: DataRecord[]) => {
        handleSubmit(onSubmit)()
    }, [onSubmit, handleSubmit]);

    const debouncedOnChange = useCallback(
        debounce((data: CustomField) => {
            onChange?.(data);
        }, 300), // 300ms delay
        [onChange]
    );

    useEffect(() => {
        setInitialState(initialValues)
    }, [])

    useEffect(() => {
        onDirtyChange?.(isDirty)
    }, [isDirty, onDirtyChange]);

    useEffect(() => {
        const comparison = { ...watchedValues }
        delete comparison['id']
        if (!isEqual(comparison, changes)) {
            setChanges(comparison)
            debouncedOnChange?.(comparison)
        }
    }, [watchedValues, debouncedOnChange, changes])

    useEffect(() => {
        return () => {
            debouncedOnChange.cancel();
        };
    }, [debouncedOnChange]);

    const isFormChanged = useMemo(() => {
        const isNewField = initialState.field_name.includes('new_field');
        return JSON.stringify(initialState) !== JSON.stringify(watchedValues) || isNewField;
    }, [initialState, watchedValues]);

    const fieldTypeDetails = fieldType[initialValues.type]

    return (
        <>
            <form onSubmit={handleSubmit(handleSave)}>
                <Card>
                    <CardHeader>
                        <CardTitle>{fieldTypeDetails.title}</CardTitle>
                        <CardDescription>{fieldTypeDetails.information}</CardDescription>
                    </CardHeader>
                    <CardContent className="space-y-6">
                        <div className="space-y-2">
                            <Label htmlFor="label">Field Name</Label>
                            <Controller
                                name="label"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <Input
                                        id="label"
                                        placeholder="Enter a field label"
                                        {...field}
                                    />
                                )}
                            />
                            {errors.label && <p className="text-sm text-red-500">{errors.label.message}</p>}
                        </div>

                        <div className="space-y-2">
                            <Label htmlFor="description">Description</Label>
                            <Controller
                                name="description"
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        id="description"
                                        placeholder="Field description"
                                        {...field}
                                    />
                                )}
                            />
                        </div>

                        <div className="space-y-2">
                            <Label htmlFor="info">Information Popup</Label>
                            <Controller
                                name="info"
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        id="info"
                                        placeholder="Field information"
                                        {...field}
                                    />
                                )}
                            />
                        </div>

                        {fieldTypeDetails.placeholder && (
                            <div className="space-y-2">
                                <Label htmlFor="placeholder">Placeholder</Label>
                                <Controller
                                    name="placeholder"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <Input
                                            id="placeholder"
                                            placeholder="Enter field placeholder"
                                            {...field}
                                        />
                                    )}
                                />
                                {errors.placeholder && <p className="text-sm text-red-500">{errors.placeholder.message}</p>}
                            </div>
                        )}

                        {initialValues.type === 'number' && (
                            <div className="space-y-2">
                                <Label htmlFor="decimal_places">Decimal Places</Label>
                                <Controller
                                    name="decimal_places"
                                    control={control}
                                    render={({ field }) => (
                                        <Select onValueChange={field.onChange} defaultValue={field.value?.toString()}>
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select decimal places" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
                                                    <SelectItem key={i} value={i.toString()}>{i}</SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    )}
                                />
                            </div>
                        )}

                        {initialValues.type === 'select' && (
                            <>
                                <div className="flex items-center space-x-2">
                                    <Controller
                                        name="allow_add"
                                        control={control}
                                        render={({ field }) => (
                                            <Switch
                                                id="allow_add"
                                                checked={field.value}
                                                onCheckedChange={field.onChange}
                                            />
                                        )}
                                    />
                                    <Label htmlFor="allow_add">Allow add to list</Label>
                                </div>

                                <div className="space-y-2">
                                    <Label htmlFor="selection_type">Selections Allowed</Label>
                                    <Controller
                                        name="selection_type"
                                        control={control}
                                        render={({ field }) => (
                                            <SegmentedControl
                                                value={field.value}
                                                onValueChange={field.onChange}
                                                options={[
                                                    { value: "single", label: "One" },
                                                    { value: "multiple", label: "Multiple" }
                                                ]}
                                            />
                                        )}
                                    />
                                </div>
                            </>
                        )}


                        {fieldTypeDetails.hasData && (
                            <div className="space-y-2">
                                <Label htmlFor="data">List Options</Label>

                                <div className="p-0 max-w-2xl mx-auto">
                                    <Controller
                                        name="data"
                                        control={control}
                                        render={({ field }) => {
                                            return (
                                                <ListEditor
                                                    value={field.value}
                                                    onChange={field.onChange}
                                                    onBlur={field.onBlur}
                                                />
                                            )
                                        }}
                                    />
                                </div>

                            </div>
                        )}
                    </CardContent>
                    <CardContent className="flex justify-end">
                        <Button variant="outline" disabled={!isFormChanged} onClick={() => setShowDiscardModal(true)}>
                            Discard
                        </Button>
                    </CardContent>
                </Card>
            </form>

            <Dialog open={showDiscardModal} onOpenChange={setShowDiscardModal}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Discard Changes</DialogTitle>
                        <DialogDescription>
                            Are you sure you want to discard changes and revert to the original field configuration?
                        </DialogDescription>
                    </DialogHeader>
                    <DialogFooter>
                        <Button variant="outline" onClick={() => setShowDiscardModal(false)}>Cancel</Button>
                        <Button variant="default" onClick={onDelete}>Discard</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default React.memo(CustomFieldEditor);