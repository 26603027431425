
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { Textarea } from "@/components/ui/textarea"
import { zodResolver } from "@hookform/resolvers/zod"
import { useState } from "react"
import { useForm } from "react-hook-form"
import * as z from "zod"

import PageWithTopBarNavigation from "@/app/features/profile/PageWithTopBarNavigation"
import { TopNavBar } from "@/app/features/profile/TopNavBar"
import TestSuiteDetailsForm from "@/app/features/testsuites/DetailsForm"
import { MainPage } from "@/app/layout2/AppLayout"
import BusyButton from "@/components/raytd/busy-button"
import { FormCard, FormCardContent, FormCardHeader, FormCardTitle } from "@/components/raytd/form-card"
import { Pill } from "@/components/raytd/pill"
import { TestType } from "@/components/raytd/test-type-pill"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form"
import { cn } from "@/lib/utils"
import { useCreateTestSuiteMutation } from "@app.raytd.com/store"
import classNames from "classnames"
import { Control, useController } from 'react-hook-form'
import { useNavigate } from "react-router-dom"
import { toast } from "sonner"

const createSchema = z.object({
    name: z.string().min(1, "Name is required"),
    description: z.string().min(1, "Description is required"),
})

const selectTypeSchema = z.object({
    type: z.enum(["compliance", "condition", "general", "numerical"], {
        required_error: "You need to select a test suite type",
    }),
})

type CreateFormValues = z.infer<typeof createSchema>
type SelectTypeFormValues = z.infer<typeof selectTypeSchema>

interface StepIndicatorProps {
    step: string;
    currentStep: string;
    label: string;
    onClick?: (step: string) => void;
}

const StepIndicator: React.FC<StepIndicatorProps> = ({ step, currentStep, label, onClick }) => {
    return (
        // <div className="relative px-8">
        <div
            className={classNames("font-medium", {
                "text-primary": currentStep === step,
                "text-muted-foreground": currentStep !== step,
                "cursor-pointer": currentStep !== step && onClick,
            })}
            onClick={() => onClick(step)}>
            {label}
        </div>
        //<div className={classNames("left-0 border-zinc-700 h-1 -bottom-2 absolute w-full", {'border-b-4' : currentStep === step})} >
        //</div> 
        // </div>
    );
};


const CreateTestSuitePage = () => {
    const [step, setStep] = useState<"create" | "selectType">("create");
    const [createData, setCreateData] = useState<CreateFormValues | null>(null);

    const [createTestSuite, { isLoading: isCreating }] = useCreateTestSuiteMutation();

    const navigate = useNavigate();

    const createForm = useForm<CreateFormValues>({
        resolver: zodResolver(createSchema),
    })

    const selectTypeForm = useForm<SelectTypeFormValues>({
        resolver: zodResolver(selectTypeSchema),
    })

    const handleCancel = () => {
        navigate('/test-suites');
    }

    const onSubmitCreate = (data: CreateFormValues) => {
        console.log("Create data:", data)
        setStep("selectType")
        setCreateData(data);
    }

    const onSubmitSelectType = async (data: SelectTypeFormValues) => {
        // Here you would typically submit the combined data or move to the next step
        console.debug('Submit data', data, createData);
        try {

            const response = await createTestSuite({
                title: createData!.name,
                description: createData!.description,
                type: data.type,
            }).unwrap();

            toast.success('Test suite created successfully');

            navigate(`/test-suites/${response.id}`);

        } catch (error) {
            console.error('Error creating test suite', error);
            toast.error('Failed to create test suite');
        }

    }

    const onStepClick = (step: "create" | "selectType") => {
        setStep(step)
    }


    return (
        <MainPage>
            <PageWithTopBarNavigation>
                <TopNavBar>
                    <div className="flex justify-between items-center gap-8">
                        <StepIndicator step="create" currentStep={step} label="Create" onClick={onStepClick} />
                        <StepIndicator step="selectType" currentStep={step} label="Select Type" />
                        <div className="text-muted-foreground">Configure</div>
                    </div>
                </TopNavBar>

                {step === "create" && (
                    <FormCard>
                        <FormCardHeader>
                            <FormCardTitle>New Test Suite</FormCardTitle>
                        </FormCardHeader>
                        <FormCardContent>

                            <TestSuiteDetailsForm
                                saveButtonText="Next"
                                cancelButtonText="Cancel"
                                onSubmit={onSubmitCreate}
                                onCancel={handleCancel}
                            />
                        </FormCardContent>
                    </FormCard>
                )}

                {step === "selectType" && (
                    <FormCard>
                        <FormCardHeader>
                            <FormCardTitle>Select Type</FormCardTitle>
                        </FormCardHeader>
                        <FormCardContent className="max-w-fit">
                            <form onSubmit={selectTypeForm.handleSubmit(onSubmitSelectType)} className="space-y-6">

                                <TestSuiteTypePicker control={selectTypeForm.control} name="type" />

                                {selectTypeForm.formState.errors.type && (
                                    <p className="text-sm text-destructive">{selectTypeForm.formState.errors.type.message}</p>
                                )}
                                <div className="flex justify-between">
                                    <Button variant="outline" type="button" onClick={() => setStep("create")}>
                                        Back
                                    </Button>
                                    <BusyButton isBusy={isCreating} type="submit">Create</BusyButton>

                                </div>
                            </form>
                        </FormCardContent>
                    </FormCard>
                )}
            </PageWithTopBarNavigation>
        </MainPage>
    )
}

interface TestSuiteType {
    type: TestType
    title: string
    description: string[]
    disabled?: boolean
}

const TEST_SUITE_TYPES: TestSuiteType[] = [
    {
        type: 'compliance',
        title: 'Compliance Test Suite',
        description: [
            'Measure the performance of building elements or spaces against specific performance criteria using our industry standard rating tool.',
            'Automatically generates a full quantitative analysis for your reports.'
        ]
    },
    {
        type: 'condition',
        title: 'Condition Assessment Test Suite',
        description: [
            'Assess the condition of specific building elements or spaces using our industry standard rating tool.',
            'Automatically generates a full quantitative analysis for your reports.'
        ]
    },
    {
        type: 'generic',
        title: 'General Test Suite',
        description: [
            'Document building elements and record comments without ratings.'
        ]
    },
    {
        type: 'numerical',
        title: 'Numerical Test Suite',
        description: ['Not yet supported'],
        disabled: true
    }
]

interface TestSuiteTypePickerProps {
    control: Control<any>
    name: string
}

const TestSuiteTypePicker: React.FC<TestSuiteTypePickerProps> = ({ control, name }) => {
    const { field } = useController({ name, control })

    return (
        <RadioGroup
            onValueChange={field.onChange}
            defaultValue={field.value}
            className="flex flex-row gap-8 overflow-x-auto w-full max-w-fit pb-8"
        >
            {TEST_SUITE_TYPES.map((suite) => (
                <SuiteTypeCard
                    key={suite.type}
                    {...suite}
                    selected={field.value === suite.type}
                />
            ))}
        </RadioGroup>
    )
}

interface SuiteTypeCardProps extends TestSuiteType {
    selected: boolean
}

function SuiteTypeCard({ type, title, description, selected, disabled }: SuiteTypeCardProps) {
    return (
        <div className="w-80 shrink-0">
            <RadioGroupItem
                value={type}
                id={type}
                className="peer sr-only"
                disabled={disabled}
            />
            <Label
                htmlFor={type}
                className="cursor-pointer"
            >
                <Card className={cn(
                    "transition-all flex flex-col",
                    selected && "border-primary",
                    disabled && "opacity-50 cursor-not-allowed"
                )}>
                    <CardHeader>
                        <CardTitle>{title}</CardTitle>
                    </CardHeader>
                    <CardContent className="flex flex-col justify-evenly h-96">
                        <div className="text-muted-foreground space-y-2 flex-1">
                            {description.map((text, index) => (
                                <p key={index}>{text}</p>
                            ))}
                        </div>
                        {selected && (<div className="flex flex-wrap justify-end"><Pill variant="inverted">Selected</Pill></div>)}
                    </CardContent>
                </Card>
            </Label>
        </div>
    )
}
export default CreateTestSuitePage;