"use client"

import { useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { ScrollArea } from "@/components/ui/scroll-area"
import { Textarea } from "@/components/ui/textarea"
import { Plus, PlusCircle, X } from "lucide-react"
import { useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { on } from "events"
import classNames from "classnames"

export type FastFillItem = {
    uuid: string;
    id: number;
    content: string;
    delete?: boolean;
}

type Props = {
    title: string
    itemName?: string
    initialItems?: FastFillItem[]
    onChange?: (items: FastFillItem[]) => void
}

function EmptyList({ itemName }: { itemName?: string }) {
    return (
        <div className="flex flex-col items-center justify-center h-[200px] text-center">
            <p className="text-sm text-zinc-500 mb-2">No {itemName?.toLowerCase() ?? 'items'} yet</p>
            <p className="text-xs text-zinc-400">Add a new {itemName?.toLowerCase() ?? 'item'} to get started</p>
        </div>
    )
}

export function FastFillEditor({ title, itemName = 'Item', initialItems = [], onChange }: Props) {
    const [items, setItems] = useState<FastFillItem[]>([]);

    const [editingId, setEditingId] = useState<string | null>(null)
    const [isAddingNewItem, setIsAddingNewItem] = useState(false)
    const { register, handleSubmit, reset } = useForm<{ content: string }>();

    const ref = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            event.stopPropagation();
            if (isAddingNewItem && ref.current && !ref.current.contains(event.target as Node)) {
                setIsAddingNewItem(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])


    useEffect(() => {
        if (initialItems.length > 0) {
            console.info('incoming items updated.', initialItems);
            setItems(initialItems.map(item => ({
                ...item,
                uuid: item.uuid || uuidv4()
            })));
        }
    }, [initialItems]);

    const onSubmit = useCallback((data: { content: string }) => {
        let updatedItems;
        if (editingId !== null) {
            updatedItems = items.map(item => item.uuid === editingId ? { ...item, content: data.content } : item);
            setItems(updatedItems);
            setEditingId(null);
        } else {
            updatedItems = [...items, { uuid: uuidv4(), id: null, content: data.content }];
            setItems(updatedItems);
            setIsAddingNewItem(false);
        }
        reset();
        if (onChange) {
            onChange(updatedItems);
        }
    }, [items, editingId, reset, onChange]);

    const handleRemove = useCallback((item) => {
        const updatedItems = items.map(i => i.uuid === item.uuid ? { ...i, delete: !i.delete } : i);
        setItems(updatedItems);
        if (onChange) {
            onChange(updatedItems);
        }
    }, [items, onChange]);

    return (
        <Card className="w-full p-0 border-0 rounded-none">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 p-2">
                <CardTitle className="text-sm font-medium p-0">
                    Fast Fill {title}
                </CardTitle>
                <Button
                    variant="link"
                    className="px-0 text-zinc-500"
                    //size="sm"
                    onClick={() => {
                        setIsAddingNewItem(!isAddingNewItem)
                        setEditingId(null)
                        reset()
                    }}
                >
                    {isAddingNewItem ? (
                        <X className="h-4 w-4" />
                    ) : (
                        <>
                            <span className="flex flex-row items-center justify-end">
                                <Plus className="h-4 w-4" /> Add
                            </span>
                            <span className="sr-only">{isAddingNewItem ? "Cancel" : `Add ${itemName}`}</span>
                        </>
                    )}
                </Button>
            </CardHeader>
            <CardContent className='px-2 py-0'>
                {isAddingNewItem && (
                    <form onSubmit={handleSubmit(onSubmit)} className="space-y-2 mb-4" ref={ref}>
                        <div className="relative">
                            <Textarea
                                placeholder={`Enter ${itemName.toLowerCase()} here...`}
                                className="resize-none pr-20"
                                {...register("content", { required: true })}
                            />
                            <Button
                                type="submit"
                                className="absolute right-2 bottom-2 bg-zinc-600 hover:bg-zinc-700 text-white"
                                size="sm"
                            >
                                Add
                            </Button>
                        </div>
                    </form>
                )}
                <ScrollArea className="h-[200px]">
                    {items.length === 0 ? (
                        <EmptyList itemName={itemName} />
                    ) : (
                        items.map(item => (
                            <div
                                key={item.uuid}
                                className="py-1">

                                {editingId === item.uuid ? (
                                    <EditableItem
                                        item={item}
                                        onSubmit={onSubmit}
                                        onCancel={() => setEditingId(null)}
                                        handleSubmit={handleSubmit}
                                        register={register}
                                    />
                                ) : (
                                    <Item
                                        item={item}
                                        onClick={() => {
                                            setEditingId(item.uuid);
                                            setIsAddingNewItem(false);
                                            reset({ content: item.content });

                                        }}
                                        onRemove={handleRemove}
                                    />
                                )}
                            </div>
                        ))
                    )}
                </ScrollArea>
            </CardContent>
        </Card>
    )
}


interface EditableItemProps {
    item: { id: number; content: string };
    onSubmit: (data: any) => void;
    onCancel: () => void;
    handleSubmit: any;
    register: any;
}

const EditableItem: React.FC<EditableItemProps> = ({ item, onSubmit, handleSubmit, register, onCancel }) => {

    const ref = useRef(null);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            event.stopPropagation();
            if (ref.current && !ref.current.contains(event.target as Node)) {
                onCancel();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onSubmit, item.content]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-2" ref={ref}>
            <div className="relative flex items-center">
                <Textarea
                    defaultValue={item.content}
                    className="resize-none pr-20"
                    {...register("content", { required: true })}
                />
                <Button
                    type="submit"
                    variant="secondary"
                    className="absolute right-2 my-auto bg-zinc-300 hover:bg-zinc-700 text-zinc-900"
                    size="sm"
                >
                    Save
                </Button>
            </div>
        </form>
    );
};



interface ItemProps {
    item: FastFillItem;
    onClick: () => void;
    onRemove: (item: FastFillItem) => void;
}

const Item: React.FC<ItemProps> = ({ item, onClick, onRemove }) => {

    const handleRemoveClick = useCallback((event) => {
        event.stopPropagation();
        onRemove(item);
    }, [item, onRemove]);

    // console.debug('Item', item);
    return (
        <div
            className="bg-zinc-100 text-zinc-950 rounded-md px-3 py-2 text-sm cursor-pointer flex flex-row items-center justify-between"
            onClick={onClick}
        >
            <div className={classNames("flex-1", { 'line-through text-zinc-600 opacity-50': item.delete })}>{item.content}</div>
            <button className="p-0.5 opacity-50 hover:opacity-100" onClick={handleRemoveClick}><X className="h-4 w-4" /></button>
        </div>
    );
};
