import AssetSpacesPage from "@/app/features/assets/AssetSpacesPage";
import SubPageLayout from "@/app/features/organisation/SubPageLayout";
import PageWithTopBarNavigation from "@/app/features/profile/PageWithTopBarNavigation";
import { TopNavBar, NavLink } from "@/app/features/profile/TopNavBar";
import { MainPage } from "@/app/layout2/AppLayout";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import AssetDetails from './AssetDetails';
import { useGetAssetQuery } from "@app.raytd.com/store";
import { useEffect } from "react";
import { useBreadcrumb } from "@/app/layout2/BreadcrumbContext";
import AssetCollaboratorsPage from "@/app/features/assets/AssetCollaboratorPage";

const AssetView = () => {
    const { setBreadcrumbName } = useBreadcrumb();
    const { assetId } = useParams<{ assetId: string }>();
    const { data: asset } = useGetAssetQuery(assetId);

    useEffect(() => {
        if (asset) {
            console.debug('setting breadcrumb name', {path: `/assets/${assetId}`, asset, name: asset?.name});
            setBreadcrumbName(`/assets/${assetId}`, asset?.name);
        }
    }, [asset]);

    console.debug({assetId});

    return (
        <MainPage>
            <PageWithTopBarNavigation>
                <TopNavBar>
                    <NavLink to="details">
                        Details
                    </NavLink>
                    <NavLink to="spaces">
                        Spaces
                    </NavLink>
                    <NavLink to="inspections">
                        Inspections
                    </NavLink>
                    <NavLink to="reports">
                        Reports
                    </NavLink>
                    <NavLink to="results">
                        Results
                    </NavLink>
                    <NavLink to="collaborators">
                        Collaborators
                    </NavLink>
                </TopNavBar>
                <div className="container mx-auto py-6 px-4">
                    <Routes>
                        <Route path="details" element={<AssetDetails assetId={assetId} />} />
                        <Route path="spaces" element={<AssetSpacesPage/>} />
                        <Route path="inspections" element={<SubPageLayout title='Inspections'><span>Not completed</span></SubPageLayout>} />
                        <Route path="reports" element={<SubPageLayout title='Reports'><span>Not completed</span></SubPageLayout>} />
                        <Route path="results" element={<SubPageLayout title='Results'><span>Not completed</span></SubPageLayout>} />
                        <Route path="collaborators" element={<AssetCollaboratorsPage assetId={assetId} />} />
                        <Route path="*" element={<Navigate to="details" />} />
                    </Routes>
                </div>
            </PageWithTopBarNavigation>
        </MainPage>
    );

}

export default AssetView;

