import { Assessment } from '../assessment/assessment.slice';
import {
  BaseQueryApi,
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { RootState } from '../root';
import { authActions } from '../auth';
import { Mutex } from 'async-mutex';
import { toast } from 'sonner';
import { addBuilding, AssetEntity } from '../asset';
import {
  createEntityAdapter,
  EntityState,
  createSelector,
} from '@reduxjs/toolkit';
import { createElement } from 'react';
import { ClassificationItem, ReportBlock } from './types';
import { TestItem } from '../tests/entity';

const API_URL = (import.meta.env.VITE_API_URL as string) + '/api/v1/';
const mutex = new Mutex();

console.debug('API_URL:', API_URL);

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.user?.token;
    const csrf = (getState() as RootState).auth.xsrfToken;

    console.debug('headers:', headers);

    // Only set these headers if we're not sending FormData
    if (!headers.get('Content-Type')?.includes('multipart/form-data')) {
      headers.set('Content-Type', 'application/json');
      headers.set('Accept', 'application/json');
    }

    headers.set('Accept', 'application/json');

    if (csrf) {
      headers.set('X-XSRF-TOKEN', csrf);
    }

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    console.info('headers:', headers);

    return headers;
  },
  credentials: 'include',
});

const customBaseQuery: BaseQueryFn = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);

  // If we get a 401 error, attempt to refresh the token
  if (result.error && result.error.status === 401) {
    // Lock the mutex so other queries wait until the token refresh is complete
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        //   const refreshResult = await baseQuery('/auth/refresh-token', api, extraOptions);

        //   if (refreshResult.data) {
        //     // Dispatch the new token to the store
        //     api.dispatch(refreshToken(refreshResult.data));

        //     // Retry the original query with the new token
        //     result = await baseQuery(args, api, extraOptions);
        //   } else {
        //     // If the refresh fails, log out the user
        //     api.dispatch(logout());
        //   }
        api.dispatch(authActions.logout());
      } finally {
        // Release the mutex no matter what happens
        release();
      }
    } else {
      // If another request is refreshing the token, wait for it to complete
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};

const assetsAdapter = createEntityAdapter<AssetEntity>();
const initialState = assetsAdapter.getInitialState();

const testItemsAdapter = createEntityAdapter({
  selectId: (item: TestItem) => item.assessmentTestId,
});

const initialTestItemsState = testItemsAdapter.getInitialState();

export const rAPI = createApi({
  reducerPath: 'assetApi',
  baseQuery: customBaseQuery,
  tagTypes: [
    'Asset',
    'Buildings',
    'CoverImage',
    'Assessment',
    'Levels',
    'Areas',
    'User',
    'Users',
    'Qualifications',
    'Offices',
    'Organisations',
    'ElementGroups',
    'ElementItems',
    'Recommendations',
    'Observations',
    'TestSuites',
    'Specifications',
    'RatingScales',
    'TestItems',
    'ReportBlocks'
  ],
  endpoints: (builder) => ({
    getAssets: builder.query<EntityState<AssetEntity, number>, void>({
      query: () => 'assets',
      transformResponse: (response: { data: AssetEntity[] }) => {
        //return response.data;
        return assetsAdapter.setAll(initialState, response.data);
      },
      providesTags: ['Asset'],
    }),
    getAsset: builder.query({
      query: (assetId) => `asset/${assetId}`,
      providesTags: ['Asset'],
    }),
    createAsset: builder.mutation({
      query: ({ organisationId, ...asset }) => ({
        url: `organisations/${organisationId}/assets`,
        method: 'POST',
        body: asset,
      }),
      invalidatesTags: ['Asset'],
    }),
    updateAsset: builder.mutation({
      query: ({ id, ...asset }) => ({
        url: `/asset/${id}`,
        method: 'PUT',
        body: asset,
      }),
      invalidatesTags: ['Asset'],
    }),
    changeUserRole: builder.mutation({
      query: ({ assetId, user_id, role }) => ({
        url: `/asset/${assetId}/role`,
        method: 'PUT',
        body: {
          user_id,
          role,
        },
      }),
      invalidatesTags: ['Asset'],
    }),
    archiveAsset: builder.mutation({
      query: (assetId) => ({
        url: `asset/${assetId}/archive`,
        method: 'PATCH',
      }),
      async onQueryStarted(assetId, lifecycleApi) {
        const getAssetsPatchResult = lifecycleApi.dispatch(
          rAPI.util.updateQueryData('getAssets', undefined, (draft) => {
            const asset = draft.entities[assetId];

            if (asset) {
              if (asset.status === 'archived') {
                asset.status = 'active';
              } else {
                asset.status = 'archived';
              }
            }
          })
        );

        try {
          await lifecycleApi.queryFulfilled;
          //          const state = lifecycleApi.getState();
          //        const updatedAsset = state.assetApi.queries['getAssets']?.data?.entities[assetId];

          //      toast.success(`Asset ${updatedAsset.status === 'active' ? 'restored' : 'archived'} successfully`);
          toast.success(`Asset updated`);
        } catch (error) {
          console.error('Error archiving asset', error);
          toast.error('Error archiving asset');
          getAssetsPatchResult.undo();
        }
      },
      // invalidatesTags: ['Asset'],
    }),
    getAssetLocations: builder.query({
      query: (assetId) => `asset/${assetId}/locations`,
    }),
    addCollaborator: builder.mutation({
      query: ({ assetId, ...collaborator }) => ({
        url: `asset/${assetId}/collaborators`,
        method: 'POST',
        body: collaborator,
      }),
      invalidatesTags: ['Asset'],
    }),
    removeCollaborator: builder.mutation({
      query: ({ assetId, userId }) => ({
        url: `asset/${assetId}/collaborators`,
        method: 'DELETE',
        body: { user_id: userId },
      }),
      invalidatesTags: ['Asset'],
    }),
    uploadCoverImage: builder.mutation({
      query: ({ assetId, coverImage }) => {
        const formData = new FormData();
        formData.append('cover_image', coverImage);
        return {
          url: `asset/${assetId}/cover`,
          method: 'POST',
          body: formData,
          headers: {
            // Explicitly remove Content-Type header if it's being set somewhere
            'Content-Type': undefined,
          },
        };
      },
      invalidatesTags: ['CoverImage'],
    }),
    getLoggedInUser: builder.query({
      query: () => 'user/profile',
      transformResponse: (response: { data: any }) => response.data,
      providesTags: ['User'],
    }),
    getTeamUsers: builder.query({
      query: () => 'users',
      transformResponse: (response: { users: any[] }) => response.users,
      providesTags: ['Users'],
    }),
    updateUserProfile: builder.mutation({
      query: (profile) => ({
        url: 'user/profile',
        method: 'PUT',
        body: profile,
      }),
      invalidatesTags: ['User'],
    }),
    deleteCoverImage: builder.mutation({
      query: (assetId) => ({
        url: `asset/${assetId}/cover`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CoverImage'],
    }),
    getAssetAssessments: builder.query({
      query: (assetId) => `asset/${assetId}/assessments`,
      providesTags: ['Assessment'],
    }),
    getAssetBuildings: builder.query({
      query: (assetId) => `asset/${assetId}/buildings`,
      providesTags: ['Buildings'],
    }),
    addBuilding: builder.mutation({
      query: (building) => ({
        url: `buildings`,
        method: 'POST',
        body: building,
      }),
      invalidatesTags: ['Buildings'],
    }),
    updateBuilding: builder.mutation({
      query: ({ building_id, ...building }) => ({
        url: `buildings/${building_id}`,
        method: 'PUT',
        body: building,
      }),
      invalidatesTags: ['Buildings'],
    }),
    getAssetLevels: builder.query({
      query: (assetId) => `asset/${assetId}/levels`,
      providesTags: ['Levels'],
    }),
    addAssetLevel: builder.mutation({
      query: (level) => ({
        url: `levels`,
        method: 'POST',
        body: level,
      }),
      invalidatesTags: ['Levels'],
    }),
    updateLevel: builder.mutation({
      query: ({ level_id, ...level }) => ({
        url: `levels/${level_id}`,
        method: 'PUT',
        body: level,
      }),
      invalidatesTags: ['Levels'],
    }),
    getAssetAreas: builder.query({
      query: (assetId) => `asset/${assetId}/areas`,
      providesTags: ['Areas'],
    }),
    addAssetArea: builder.mutation({
      query: (area) => ({
        url: `rooms`,
        method: 'POST',
        body: area,
      }),
      invalidatesTags: ['Areas'],
    }),
    updateArea: builder.mutation({
      query: ({ room_id, ...area }) => ({
        url: `rooms/${room_id}`,
        method: 'PUT',
        body: area,
      }),
      invalidatesTags: ['Areas'],
    }),

    getUserQualifications: builder.query({
      query: () => `qualifications`,
      transformResponse: (response: { data: any[] }) => response.data,
      providesTags: ['Qualifications'],
    }),
    createUserQualification: builder.mutation({
      query: (qualification) => ({
        url: `qualifications`,
        method: 'POST',
        body: qualification,
      }),
      invalidatesTags: ['Qualifications'],
    }),
    updateUserQualification: builder.mutation({
      query: ({ qualification_id, ...qualification }) => ({
        url: `qualifications/${qualification_id}`,
        method: 'PUT',
        body: qualification,
      }),
      invalidatesTags: ['Qualifications'],
    }),
    deleteUserQualification: builder.mutation({
      query: (qualification_id) => ({
        url: `qualifications/${qualification_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Qualifications'],
    }),

    getOffices: builder.query({
      query: () => 'offices',
      transformResponse: (response: { data: any[] }) => response.data,
      providesTags: ['Offices'],
    }),

    createOffice: builder.mutation({
      query: (office) => ({
        url: 'offices',
        method: 'POST',
        body: office,
      }),
      invalidatesTags: ['Offices'],
    }),

    deleteOffice: builder.mutation({
      query: (officeId) => ({
        url: `offices/${officeId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Offices'],
    }),

    updateOffice: builder.mutation({
      query: ({ id, ...office }) => ({
        url: `offices/${id}`,
        method: 'PUT',
        body: office,
      }),
      invalidatesTags: ['Offices'],
    }),

    getAdminOrganisations: builder.query({
      query: () => 'admin/organisations',
      transformResponse: (response: { data: any[] }) => response.data,
      providesTags: ['Organisations'],
    }),
    getAdminUsers: builder.query({
      query: (organisationId) =>
        organisationId ? `admin/users/${organisationId}` : 'admin/users',
      transformResponse: (response: { data: any[] }) => response.data,
      providesTags: ['Users'],
    }),

    deleteAdminUser: builder.mutation({
      query: ({ userId }) => ({
        url: `admin/users/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Users'],
    }),

    getOrganisation: builder.query({
      query: (organisationId) => `organisations/${organisationId}`,
      transformResponse: (response: { data: any }) => response.data,
      providesTags: ['Organisations'],
    }),

    updateOrganisation: builder.mutation({
      query: ({ id, ...organisation }) => ({
        url: `organisations/${id}`,
        method: 'PUT',
        body: organisation,
      }),
      invalidatesTags: ['Organisations'],
    }),

    leaveOrganisation: builder.mutation({
      query: ({ id, user_id }) => ({
        url: `organisations/${id}/leave`,
        method: 'DELETE',
        body: {
          user_id: user_id,
        },
      }),
      invalidatesTags: [
        'Organisations',
        'Users',
        'Offices',
        'Asset',
        'Buildings',
        'Levels',
        'Areas',
        'User',
      ],
    }),

    inviteTeamMember: builder.mutation({
      query: (user) => ({
        url: 'organisations/invite',
        method: 'POST',
        body: user,
      }),
      invalidatesTags: ['Users'],
    }),

    createUser: builder.mutation({
      query: (user) => ({
        url: 'register',
        method: 'POST',
        body: user,
      }),
      invalidatesTags: ['Users'],
    }),

    updateUserRole: builder.mutation({
      query: ({ user_id, organisation_id, role }) => ({
        url: `users/${user_id}/organisations/${organisation_id}`,
        method: 'PUT',
        body: { role },
      }),
      invalidatesTags: ['Users'],
    }),

    getElementGroups: builder.query({
      query: (organisationId) =>
        `classification-trees/organisation/${organisationId}`,
      transformResponse: (response: { data: any[] }) => response.data,
      providesTags: ['ElementGroups'],
    }),
    getElementGroup: builder.query({
      query: (elementGroupId) => `classification-trees/${elementGroupId}`,
      transformResponse: (response: { data: any }) => response.data,
      providesTags: ['ElementGroups'],
    }),
    createElementGroup: builder.mutation({
      query: (elementGroup) => ({
        url: 'classification-trees',
        method: 'POST',
        body: elementGroup,
      }),
      invalidatesTags: ['ElementGroups'],
    }),
    updateElementGroup: builder.mutation({
      query: ({ id, ...elementGroup }) => ({
        url: `classification-trees/${id}`,
        method: 'PUT',
        body: elementGroup,
      }),
      invalidatesTags: ['ElementGroups'],
    }),

    getElementTree: builder.query<ClassificationItem[], number>({
      query: (treeId) => `classification-trees/${treeId}/items`,
      transformResponse: (response: ClassificationItem[]) => {
        // Add level to each item
        const addLevel = (
          items: ClassificationItem[],
          level = 0
        ): ClassificationItem[] => {
          return items.map((item) => ({
            ...item,
            level,
            children: addLevel(item.children || [], level + 1),
          }));
        };
        return addLevel(response);
      },
      providesTags: ['ElementItems'],
    }),

    archiveElementTreeForUser: builder.mutation({
      query: (classificationTreeId) => ({
        url: `classification-trees/${classificationTreeId}/status`,
        method: 'PUT',
      }),
      invalidatesTags: ['ElementGroups'],
    }),

    // Archive Classification Tree Mutation
    archiveElementTree: builder.mutation({
      query: (classificationTreeId) => ({
        url: `classification-trees/${classificationTreeId}/archive`,
        method: 'PUT',
      }),
      invalidatesTags: ['ElementGroups'],
    }),

    duplicateClassificationTree: builder.mutation({
      query: (classificationTreeId) => ({
        url: `classification-trees/${classificationTreeId}/duplicate`,
        method: 'POST',
      }),
      invalidatesTags: ['ElementGroups'],
    }),

    //POST classification-trees/{classificationTree}/items
    createClassificationItem: builder.mutation({
      query: ({ classification_tree_id, ...item }) => ({
        url: `classification-trees/${classification_tree_id}/items`,
        method: 'POST',
        body: item,
      }),
      invalidatesTags: ['ElementItems'],
    }),
    // put classification-items/{classificationItem}
    updateClassificationItem: builder.mutation({
      query: ({ classificationItem, ...item }) => ({
        url: `classification-items/${classificationItem}`,
        method: 'PUT',
        body: item,
      }),
      invalidatesTags: ['ElementItems'],
    }),

    // Observations Endpoints
    addObservation: builder.mutation({
      query: ({ classificationItem, observation }) => ({
        url: `classification-trees/element/${classificationItem}/observations`,
        method: 'POST',
        body: observation,
      }),
    }),
    getObservations: builder.query({
      query: ({ classificationItem, page = 1, perPage = 10 }) => ({
        url: `classification-trees/element/${classificationItem}/observations`,
        params: { page, per_page: perPage },
      }),
      providesTags: ['Observations'],
    }),
    updateObservation: builder.mutation({
      query: ({ classificationItem, comment, observation }) => ({
        url: `classification-trees/element/${classificationItem}/observations/${comment}`,
        method: 'PUT',
        body: observation,
      }),
    }),
    deleteObservation: builder.mutation({
      query: ({ classificationItem, comment }) => ({
        url: `classification-trees/element/${classificationItem}/observations/${comment}`,
        method: 'DELETE',
      }),
    }),

    // Recommendations Endpoints
    addRecommendation: builder.mutation({
      query: ({ classificationItem, recommendation }) => ({
        url: `classification-trees/element/${classificationItem}/recommendations`,
        method: 'POST',
        body: recommendation,
      }),
    }),
    getRecommendations: builder.query({
      query: ({ classificationItem, page = 1, perPage = 10 }) => ({
        url: `classification-trees/element/${classificationItem}/recommendations`,
        params: { page, per_page: perPage },
      }),
      providesTags: ['Recommendations'],
    }),
    updateRecommendation: builder.mutation({
      query: ({ classificationItem, recommendationId, recommendation }) => ({
        url: `classification-trees/element/${classificationItem}/recommendations/${recommendationId}`,
        method: 'PUT',
        body: recommendation,
      }),
    }),
    deleteRecommendation: builder.mutation({
      query: ({ classificationItem, recommendationId }) => ({
        url: `classification-trees/element/${classificationItem}/recommendations/${recommendationId}`,
        method: 'DELETE',
      }),
    }),

    // Observations Mutations
    handleObservations: builder.mutation({
      query: ({ classificationItem, ...rest }) => ({
        url: `classification-trees/element/${classificationItem}/observations`,
        method: 'POST',
        body: { observations: rest.observations },
      }),
      invalidatesTags: ['Observations'],
    }),

    // Recommendations Mutations
    handleRecommendations: builder.mutation({
      query: ({ classificationItem, ...rest }) => ({
        url: `classification-trees/element/${classificationItem}/recommendations`,
        method: 'POST',
        body: { recommendations: rest.recommendations },
      }),
      invalidatesTags: ['Recommendations'],
    }),

    getTestSuites: builder.query({
      query: () => 'test-suites',
      transformResponse: (response: { data: any[] }) =>
        response.data.map((suite: any) => ({
          ...suite,
          inspections: suite.total_assessments,
          categories: suite.total_categories,
          subCategories: suite.total_specifications,
          tests: suite.total_specifications,
          status: suite.status === 'inuse' ? 'active' : 'archived',
        })),
      providesTags: ['TestSuites'],
    }),
    getTestSuite: builder.query({
      query: (testSuiteId) => 'test-suites/' + testSuiteId,
      transformResponse: (response: { data: any }) => {
        const suite = response.data;
        return {
          ...suite,
          inspections: suite.total_assessments,
          categories: suite.total_categories,
          subCategories: suite.total_specifications,
          tests: suite.total_specifications,
          status: suite.status === 'inuse' ? 'active' : 'archived',
        };
      },
      providesTags: ['TestSuites'],
    }),
    createTestSuite: builder.mutation({
      query: (newTestSuite) => ({
        url: 'test-suites',
        method: 'POST',
        body: newTestSuite,
      }),
      invalidatesTags: ['TestSuites'],
    }),
    updateTestSuite: builder.mutation({
      query: ({ id, ...testSuite }) => ({
        url: `test-suites/${id}`,
        method: 'PUT',
        body: testSuite,
      }),
      invalidatesTags: ['TestSuites'],
    }),

    updateTestSuiteFields: builder.mutation({
      query: ({ id, ...fields }) => ({
        url: `test-suites/${id}/fields`,
        method: 'PUT',
        body: fields,
      }),
      invalidatesTags: ['TestSuites'],
    }),

    getTestSpecifications: builder.query({
      query: (testSuiteId) => `test-suites/${testSuiteId}/tests`,
      transformResponse: (response: { data: any[] }) => response.data,
      providesTags: ['Specifications'],
    }),

    getRatingScales: builder.query({
      query: () => 'rating-scales',
      transformResponse: (response: { data: any[] }) => response.data,
      providesTags: ['RatingScales'],
    }),

    getTestItems: builder.query<EntityState<TestItem, number>, number>({
      query: (assessmentId) => `assessments/${assessmentId}/test-items`,
      //transformResponse: (response: {data: TestItem[]}) => response.data,
      transformResponse: (response: { data: TestItem[] }) => {
        // Transform the response into a normalized state using the adapter
        return testItemsAdapter.setAll(
          testItemsAdapter.getInitialState(),
          response.data
        );
      },
      providesTags: (result) =>
        result
          ? [
              ...result.ids.map((id) => ({ type: 'TestItems' as const, id })),
              { type: 'TestItems' as const, id: 'LIST' },
            ]
          : [{ type: 'TestItems' as const, id: 'LIST' }],
    }),

    getTestItemById: builder.query({
      query: (id) => `tests/${id}`,
      transformResponse: (response: { data: TestItem }) => {
        // Normalize the single item response using the adapter
        return testItemsAdapter.upsertOne(
          testItemsAdapter.getInitialState(),
          response.data
        );
      },
      providesTags: (result, error, id) => [{ type: 'TestItems', id }],
    }),

    getAssessment: builder.query<Assessment, number>({
      query: (assessmentId) => `assessments/${assessmentId}`,
      transformResponse: (response: { data: Assessment }) => response.data,
      providesTags: ['Assessment'],
    }),


    getReportItems: builder.query({
      query: (assessmentId) => `reports/${assessmentId}/test-items`,
      transformResponse: (response: { data: any[] }) => response.data,
    }),


    getReportBlocks: builder.query<ReportBlock[], void>({
      query: () => 'report-blocks',
      transformResponse: (response: { data: ReportBlock[] }) => response.data,
      providesTags: ['ReportBlocks'],
    }),
    // Fetch a single ReportBlock by ID
    getReportBlock: builder.query<ReportBlock, string>({
      query: (id) => `report-blocks/${id}`,
      transformResponse: (response: { data: ReportBlock }) => response.data,
      providesTags: ['ReportBlocks'],
    }),
    // Create a new ReportBlock



    createReportBlock: builder.mutation({
      query: (newReportBlock) => ({
        url: 'report-blocks',
        method: 'POST',
        body: newReportBlock,
      }),
      invalidatesTags: ['ReportBlocks'],
    }),
    // Update an existing ReportBlock
    updateReportBlock: builder.mutation({
      query: ({ id, ...block }) => ({
        url: `report-blocks/${id}`,
        method: 'PUT',
        body: block,
      }),
      invalidatesTags: ['ReportBlocks'],
    }),
    // Delete a ReportBlock
    deleteReportBlock: builder.mutation<{ success: boolean }, number>({
      query: (id) => ({
        url: `report-blocks/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ReportBlocks'],
    }),

    archiveReportBlockForUser: builder.mutation({
      query: (id) => ({
        url: `report-blocks/${id}/status`,
        method: 'PUT',
      }),
      invalidatesTags: ['ReportBlocks'],
    }),

    // Archive Classification Tree Mutation
    archiveReportBlock: builder.mutation({
      query: (id) => ({
        url: `report-blocks/${id}/archive`,
        method: 'PUT',
      }),
      invalidatesTags: ['ReportBlocks'],
    }),

    duplicateReportBlock: builder.mutation({
      query: (id) => ({
        url: `report-blocks/${id}/duplicate`,
        method: 'POST',
      }),
      invalidatesTags: ['ReportBlocks'],
    }),

  }),
});

export const {
  useGetAssetsQuery,
  useGetAssetQuery,
  useCreateAssetMutation,
  useUpdateAssetMutation,
  useArchiveAssetMutation,
  useGetAssetLocationsQuery,
  useAddCollaboratorMutation,
  useRemoveCollaboratorMutation,
  useUploadCoverImageMutation,
  useDeleteCoverImageMutation,
  useGetAssetAssessmentsQuery,
  useGetAssetBuildingsQuery,
  useAddBuildingMutation,
  useUpdateBuildingMutation,
  useGetAssetLevelsQuery,
  useUpdateLevelMutation,
  useAddAssetLevelMutation,
  useGetAssetAreasQuery,
  useAddAssetAreaMutation,
  useUpdateAreaMutation,
  useGetLoggedInUserQuery,
  useGetTeamUsersQuery,
  useUpdateUserProfileMutation,

  useGetUserQualificationsQuery,
  useCreateUserQualificationMutation,
  useUpdateUserQualificationMutation,
  useDeleteUserQualificationMutation,

  useGetOfficesQuery,
  useCreateOfficeMutation,
  useDeleteOfficeMutation,
  useUpdateOfficeMutation,

  useGetOrganisationQuery,
  useUpdateOrganisationMutation,
  useLeaveOrganisationMutation,

  useInviteTeamMemberMutation,
  useCreateUserMutation,
  useUpdateUserRoleMutation,

  useGetAdminOrganisationsQuery,
  useGetAdminUsersQuery,
  useDeleteAdminUserMutation,
  useChangeUserRoleMutation,

  useGetElementGroupsQuery,
  useGetElementGroupQuery,
  useCreateElementGroupMutation,
  useUpdateElementGroupMutation,
  useArchiveElementTreeForUserMutation,
  useArchiveElementTreeMutation,
  useDuplicateClassificationTreeMutation,

  useGetElementTreeQuery,
  useCreateClassificationItemMutation,
  useUpdateClassificationItemMutation,

  useAddObservationMutation,
  useGetObservationsQuery,
  useUpdateObservationMutation,
  useDeleteObservationMutation,

  useAddRecommendationMutation,
  useGetRecommendationsQuery,
  useUpdateRecommendationMutation,
  useDeleteRecommendationMutation,

  useHandleObservationsMutation,
  useHandleRecommendationsMutation,

  useGetTestSuitesQuery,
  useGetTestSuiteQuery,
  useCreateTestSuiteMutation,
  useUpdateTestSuiteMutation,
  useUpdateTestSuiteFieldsMutation,

  useGetTestSpecificationsQuery,

  useGetRatingScalesQuery,

  useGetTestItemsQuery,
  useGetAssessmentQuery,

  useGetReportItemsQuery,

  useGetReportBlocksQuery,
  useGetReportBlockQuery,
  useCreateReportBlockMutation,
  useUpdateReportBlockMutation,
  useDeleteReportBlockMutation,

  useArchiveReportBlockForUserMutation,
  useArchiveReportBlockMutation,
  useDuplicateReportBlockMutation,

} = rAPI;

export const selectAssetsResult = rAPI.endpoints.getAssets.select();

export const selectAssetsData = createSelector(
  selectAssetsResult,
  (result) => result?.data ?? initialState
);

export const { selectAll: selectAllAssets, selectById: selectAssetById } =
  assetsAdapter.getSelectors(selectAssetsData);


export const selectTestItemsResult = (assessmentId: number) => 
  rAPI.endpoints.getTestItems.select(assessmentId);

export const selectTestItemsData = createSelector(
  // Use a tuple of selectors to maintain referential stability
  [
    (state: RootState) => state,
    (_state: RootState, assessmentId: number) => assessmentId
  ],
  (state, assessmentId) => {
    const result = selectTestItemsResult(assessmentId)(state);
    return result.data ?? testItemsAdapter.getInitialState();
  }
);

export const {
  selectAll: selectAllTestItemsAdapter,
  selectById: selectTestItemByIdAdapter,
  selectIds: selectTestItemIdsAdapter,
} = testItemsAdapter.getSelectors();


export const selectAllTestItems = createSelector(
  [
    (state: RootState, assessmentId: number) => selectTestItemsData(state, assessmentId)
  ],
  (testItemsState) => selectAllTestItemsAdapter(testItemsState)
);

export const selectTestItemById = createSelector(
  [
    (state: RootState, assessmentId: number) => selectTestItemsData(state, assessmentId),
    (_state: RootState, _assessmentId: number, itemId: number) => itemId
  ],
  (testItemsState, itemId) => selectTestItemByIdAdapter(testItemsState, itemId)
);